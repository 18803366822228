// LoginPage.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import LoginPageForm from "../../../components/Auth/LoginPageForm/LoginPageForm";
import ForgotPasswordEmail from "../../../components/Auth/ForgotPassword/ForgotPasswordEmailForm/ForgotPasswordEmailForm";
import "./LoginPage.css";

const LoginPage = () => {
  const [displayForgot, setDisplayForgot] = useState(false);

  const handleDisplayForgot = () => {
    setDisplayForgot(!displayForgot);
  };

  const largeurEcran = window.innerWidth*2;
  console.log(largeurEcran);

  return (
    <>
      <div className="login-container">
        <motion.div
          animate={{ x: displayForgot ? -largeurEcran : 0 }}
          transition={{ duration: 0.8 }}
          className="login"
        >
          <LoginPageForm handleDisplayForgot={handleDisplayForgot} />
        </motion.div>

        <motion.div
          initial={{ x: largeurEcran }}
          animate={{ x: displayForgot ? 0 : largeurEcran }}
          transition={{ duration: 0.8 }}
          className="passwordReset"
        >
          <ForgotPasswordEmail handleDisplayForgot={handleDisplayForgot} />
        </motion.div>
      </div>
    </>
  );
};

export default LoginPage;
