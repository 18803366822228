import * as React from "react";
import { useRef, useEffect,useContext } from "react";
import { motion, sync, useCycle } from "framer-motion";
import { useDimensions } from "./useDimensions";
import { MenuToggle } from "./MenuToggle";
import { useSidebar } from "../../context/SideBarContext";

import "./Header.css";

import "./Header.css";
import Navigations from "./Navigations";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const Header = ({ setIsHeaderOpen }) => {

  const { siderOpen, toggleSider, headerOpen, toggleHeader } = useSidebar(); 

   //Responsive effacer le contenu de la page.
  const isMobile = window.innerWidth <= 1300;

  useEffect(() => {
    
    // Correction problème liste droulante active en format téléphone.
    document.querySelectorAll(".open ul").forEach(function (ulElement) {
      ulElement.classList.add("disableUl");
    });
    if (headerOpen) {

      document.querySelectorAll(".open ul").forEach(function (ulElement) {
        ulElement.classList.remove("disableUl");
      });
      if(!siderOpen){
        //Sider CHANGE
        console.log("Sider CHANGE")
        toggleSider()
      }
   
      var pageContent = document.getElementById("pageContent");
      setIsHeaderOpen(true);
      pageContent.style.display = isMobile ? "none" : "block";
    } else {
      setIsHeaderOpen(false);
      setTimeout(()=>{
      var pageContent = document.getElementById("pageContent");
      pageContent.style.display = "block";
      }, 1000);
    }

    //Correction lien qui sont toujour activable mais invisible
    const links = document.querySelectorAll('a[id="headerLink"]');
    if (!headerOpen) {
      setTimeout(() => {
        links.forEach((link) => {
          link.style.display = "none";
        });
      }, 340); 
    } else {
     
      links.forEach((link) => {
        link.style.display = "flex";
      });
    }
  }, [headerOpen]);

  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <div>
      <motion.nav
        initial={false}
        animate={headerOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        className={"nav" + headerOpen ? "open" : ""}
      >
        <motion.div className="background" variants={sidebar} />
        <Navigations />
        <MenuToggle toggle={() => toggleHeader()} />
      </motion.nav>
    </div>
  );
};

export default Header;
