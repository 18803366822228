import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { useAuth } from '../../context/AuthContext';

const Navigations = () => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const headerLinks = [
    { title: "Accueil", url: "/", icon: "HomeOutlined" },
    { title: "Planning", url: "/Planning", icon: "CarryOutOutlined" },
    { title: "Impots", url: "/Impots", icon: "EuroOutlined" },
    { title: "Documents", url: "/documents", icon: "FolderOpenOutlined" },
    { title: "Contrats", url: "/contrats", icon: "FormOutlined" },
  ];

  const itemIds = headerLinks.map((_, index) => index);

  const headerLinksParents = [
    { title: "Accueil", url: "/", icon: "HomeOutlined" },
    { title: "Planning", url: "/Planning", icon: "CarryOutOutlined" },
    { title: "Documents", url: "/documents", icon: "FolderOpenOutlined" },
    { title: "Contrats", url: "/contrats", icon: "FormOutlined" },
  ];

  const itemIdsParents = headerLinksParents.map((_, index) => index);

  const { user } = useAuth();

  return (
    <>
      {user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE") ? (
        <motion.ul variants={variants}>
          {itemIds.map(i => (
            <MenuItem i={i} key={i} headerLink={headerLinks[i]} />
          ))}
        </motion.ul>
      ) : (
        <motion.ul variants={variants}>
          {itemIdsParents.map(i => (
            <MenuItem i={i} key={i} headerLink={headerLinksParents[i]} />
          ))}
        </motion.ul>
      )}
    </>
  );
};

export default Navigations;
