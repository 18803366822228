import React, { useState } from 'react';
import { Card, Modal } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './Contrat.css';

import { ContratInfo } from './ContratInfo/ContratInfo';
import ContratEdit from './ContratEdit/ContratEdit';
import { useAuth } from '../../context/AuthContext';
const { Meta } = Card;

const Contrat = ({ contrat }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const { user } = useAuth();

  const handleInfoClick = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleEditClick = () => {
    setModalEditVisible(true);
  };

  const handleModalEditCancel = () => {
    setModalEditVisible(false);
  };

  const canEditContrat = user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE');

  return (
    <>
      <Card
        className="contrat-card"
        cover={<img src={contrat.Photo} className="enfant-photo" alt={contrat.Enfant['Nom complet']} />}
        actions={[
          canEditContrat && <EditOutlined key="edit" onClick={handleEditClick} />,
          <InfoCircleOutlined key="info" onClick={handleInfoClick} />,
        ].filter(Boolean)}
      >
        <Meta
          title={contrat.Enfant['Nom complet']}
          description={
            <div>
              <p>Statut: {contrat.Statut}</p>
              <p>Age: {contrat.Enfant.Age}</p>
              <p>Date de début: {contrat['Date de début']}</p>
              <p>Date de fin: {contrat['Date de fin']}</p>
            </div>
          }
        />
      </Card>

      <Modal
        title="Information du contrat"
        open={modalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={800}
      >
        <ContratInfo contrat={contrat} />
      </Modal>

      <Modal
        className='AjoutAddEditContrat'
        title="Edition d'un contrat"
        open={modalEditVisible}
        onCancel={handleModalEditCancel}
        footer={null}
      >
        <ContratEdit contrat={contrat} />
      </Modal>
    </>
  );
};

export default Contrat;
