import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Button } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#000",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    fontSize: "12px",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: "15px",
    borderBottomWidth: 1,
    borderBottomColor: "#262626",
  },
  tableCol: {
    width: "30%",
    textAlign: "center",
  },
  columnHeader: {
    textAlign: "center",
  },
  colMois: {
    backgroundColor: "#beddf8",
  },
  colSalaire: {
    backgroundColor: "#f29eff",
  },
  colAvantagesNature: {
    backgroundColor: "#90ee90",
  },
  colPlus8heures: {
    backgroundColor: "#ff8b7e",
  },
  colMoins8heures: {
    backgroundColor: "#f1e572",
  },
  colHeureComplementaire: {
    backgroundColor: "#97c1f9",
  },

  montantText : {
    fontSize : "12px"

  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    fontSize: '15px',
    borderTopWidth: 2,
    borderTopColor: "#000",
    paddingTop: 5,
  },
  totalLabel: {
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: 15,
  },
  totalValue: {
    textAlign: "center",
  },
  montant: {
    marginTop: 20,
    backgroundColor: "#f0f0f0",
  },
  montantBorderTop : {
    borderTopWidth: 1,
    borderTopColor: "#a6a6a6",
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signe :{
    fontSize : "20px" ,
  },
});

const TableHeader = () => (
  <View style={styles.tableHeader}>
    <Text style={[styles.columnHeader, styles.tableCol]}>Mois</Text>
    <Text style={[styles.columnHeader, styles.tableCol]}>
      Salaire net imposable
    </Text>
    <Text style={[styles.columnHeader, styles.tableCol]}>
      Avantages en nature
    </Text>
    <Text style={[styles.columnHeader, styles.tableCol]}>
      Nombre de jours réels d'accueil de 8 heures et plus
    </Text>
    <Text style={[styles.columnHeader, styles.tableCol]}>
      Nombre d'heures réelles d'accueil de moins de 8 heures
    </Text>
    <Text style={[styles.columnHeader, styles.tableCol]}>
      Nombre d'heures réelles complémentaires
    </Text>
  </View>
);

const TableView = ({ impotsData, totals, montantDeclarer }) => (
  <View style={styles.section}>
    <TableHeader />
    {impotsData.map((item, index) => (
      <View key={index} style={styles.tableRow}>
        <View style={[styles.tableCol, styles.colMois]}>
          <Text>{item.mois}</Text>
        </View>
        <View style={[styles.tableCol, styles.colSalaire]}>
          <Text>{item.salaire}</Text>
        </View>
        <View style={[styles.tableCol, styles.colAvantagesNature]}>
          <Text>{item.repas}</Text>
        </View>
        <View style={[styles.tableCol, styles.colPlus8heures]}>
          <Text>{item.plus8heures}</Text>
        </View>
        <View style={[styles.tableCol, styles.colMoins8heures]}>
          <Text>{item.moins8heures}</Text>
        </View>
        <View style={[styles.tableCol, styles.colHeureComplementaire]}>
          <Text>{item.heureComplementaire}</Text>
        </View>
      </View>
    ))}
    <View style={styles.totalRow}>
      <Text style={styles.totalLabel}>TOTAL</Text>
      <Text style={[styles.totalValue,styles.colSalaire]}>{totals.salaire} €</Text>
      <Text style={[styles.totalValue,styles.colAvantagesNature]}>{totals.avantagesNature} €</Text>
      <Text style={[styles.totalValue,styles.colPlus8heures]}>{totals.plus8heures} €</Text>
      <Text style={[styles.totalValue,styles.colMoins8heures]}>{totals.moins8heures} €</Text>
      <Text style={[styles.totalValue,styles.colHeureComplementaire]}>{totals.heureComplementaire} €</Text>
    </View>

    <View style={styles.montant}>
      <View style={styles.montantBorderTop}>
        <Text style={[styles.totalLabel, styles.montantText]}> <Text style={styles.colSalaire}>Salaire </Text> <Text style={styles.signe}>+</Text> <Text style={styles.colAvantagesNature}>Avantages en nature</Text></Text>
        <Text style={[styles.totalValue, styles.montantText]}>
          {(
            parseFloat(totals.salaire) + parseFloat(totals.avantagesNature)
          ).toFixed(2)}{" "}
          €
        </Text>
      </View>

      <View style={styles.montantBorderTop}>
      <Text style={[styles.totalLabel, styles.montantText]}><Text style={styles.signe}>- </Text> <Text style={styles.colPlus8heures}>Abbatement 8h+</Text></Text>
        <Text style={[styles.totalValue, styles.montantText]}>{totals.plus8heures} €</Text>
      </View>
      <View style={styles.montantBorderTop}>
        <Text style={[styles.totalLabel, styles.montantText]}><Text style={styles.signe}>- </Text> <Text style={styles.colMoins8heures}>Abbatement 8h-</Text></Text>
        <Text style={[styles.totalValue, styles.montantText]}>{totals.moins8heures} €</Text>
      </View>
      <View style={[styles.montantBorderTop]}>
        <Text style={[styles.totalLabel]}>
          Montant à déclarer
        </Text>
        <Text style={[styles.totalValue]}>
          {montantDeclarer} €
        </Text>
      </View>
    </View>
  </View>
);

const ImpotsTabPDF = ({ year,impotsData, totals, montantDeclarer, nomEnfant }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text>{nomEnfant} - {year}</Text>
      <TableView
        impotsData={impotsData}
        totals={totals}
        montantDeclarer={montantDeclarer}
      />
    </Page>
  </Document>
);

function ViewPDF({ year, impotsData, totals, montantDeclarer,nomEnfant }) {
  console.log(montantDeclarer);
  return (
    <div>
      <PDFDownloadLink
        document={
          <ImpotsTabPDF
            impotsData={impotsData}
            year={year}
            totals={totals}
            montantDeclarer={montantDeclarer}
            nomEnfant={nomEnfant}
          />
        }
        fileName={`${year} - ${nomEnfant}`}
      >
        {({ loading }) =>
          loading ? (
            "Chargement"
          ) : (
            <Button icon={<FilePdfOutlined />} type="primary" danger>
              Télécharger en pdf
            </Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
}

export default ViewPDF;
