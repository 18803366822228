import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  const { user } = useAuth();
  

  console.log("Authentifier : " + isAuthenticated)

  

  // Si l'utilisateur est connecté, renvoyer l'élément de la route
  // Sinon, rediriger vers la page de connexion
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
