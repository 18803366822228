import React from "react";
import { AddFichier } from "../DocumentsApi";
import { useState } from "react";
import { Modal, Input, Upload, message } from "antd";
import { UploadOutlined, FileAddOutlined } from "@ant-design/icons";

const AddFile = ({ folderName, idFolder, token, forceUpdate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [fileName, setFileName] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddFile = () => {
    AddFichier({
      nom: fileName,
      idFolder: idFolder,
      file: fileList[0],
      token: token,
      forceUpdate: forceUpdate,
    });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Chaque changement du champs de saisie
  const handleInputChange = (e) => {
    setFileName(e.target.value);
  };

  const onFileChange = (info) => {
    // Gérer les changements de fichier lors de la sélection dans l'Upload
    const uploadedFile = info.file.originFileObj; // Access the File object

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    setFileList([info.file]); // Set fileList with the updated File object
  };

  return (
    <>
      <FileAddOutlined
        style={{ fontSize: "25px", color: "#4E89FF" }}
        onClick={showModal}
      />

      <Modal
        title={`Ajouter un fichier dans "${folderName}"`}
        open={isModalVisible}
        onOk={handleAddFile}
        onCancel={handleCancel}
      >
        <p>Veuillez saisir le nom du fichier :</p>
        <Input
          value={fileName}
          onChange={handleInputChange}
          placeholder="Nom du fichier"
        />
        <br />
        <br />
        <p>Sélectionnez un seul fichier en le glissant-déposant :</p>
        <Upload.Dragger
          onChange={onFileChange}
          fileList={fileList}
          beforeUpload={() => false} // Prevent automatic upload, as it's handled manually in handleOk
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">
            Cliquez ou glissez-déposez pour télécharger
          </p>
        </Upload.Dragger>
      </Modal>
    </>
  );
};

export default AddFile;
