// Importez useState également
import React, { useState, useEffect } from 'react';
// Importez tout le nécessaire depuis 'antd'
import { Form, Input, Button, DatePicker, TimePicker, Checkbox, Tabs, Select, Row, Col, message } from 'antd';
import { useAuth } from "../../../context/AuthContext.js";
import './PlanningModalAdd.css';

import { SaveOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

const PlanningModalAdd = ({ closeAddEventModal, refreshEvents }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [contrats, setContrats] = useState([]);
  const [formPonctuelle] = Form.useForm();
  const [formHebdomadaire] = Form.useForm();

  useEffect(() => {
    const fetchContrats = async () => {
      try {
        const response = await fetch('https://api.uama.fr/v1.0/contrat/index?limit=inf', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = await response.json();
        setContrats(data.contrats);
      } catch (error) {
        console.error('Error fetching contrats:', error);
      }
    };

    fetchContrats();
  }, [token]);


  const onFinishPlanificationPonctuelle = async (values) => {
    values.date = values.date.format('DD-MM-YYYY');
    values.heureDebut = values.heureDebut.format('HH:mm:ss');
    values.heureFin = values.heureFin.format('HH:mm:ss');
    // Logique pour la planification ponctuelle
    onFinish(values);
  };

  const onFinishPlanificationHebdomadaire = async (values) => {
    // Logique pour la planification hebdomadaire

    console.log('values date fin : ', values.periode.dateDebut[1].format('DD-MM-YYYY'))
    values.contrat = values.contratHebdomadaire;
    values.periode.dateFin = values.periode.dateDebut[1].format('DD-MM-YYYY');
    values.periode.dateDebut = values.periode.dateDebut[0].format('DD-MM-YYYY');
    values.periode.heureDebut = values.heureDebutHebdomadaire.format('HH:mm:ss')
    values.periode.heureFin = values.heureFinHebdomadaire.format('HH:mm:ss')

    onFinish(values)
  };

  const onFinish = async (values) => {
    console.log('onFinish', values);


    try {
      setLoading(true);
      const response = await fetch('https://api.uama.fr/v1.0/planning/saisie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(values)
      });

      if (response.ok) {
        const data = await response.json();
        closeAddEventModal();
        message.success(data.message);
        refreshEvents();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
    } catch (error) {
      console.error('Error:', error.message);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };


  return (

    <Tabs defaultActiveKey="1">
      <TabPane tab="Planification ponctuelle" key="1">
        <div className="form-container">

          <Form
            name="contrat"
            layout="vertical"
            onFinish={onFinishPlanificationPonctuelle}
            form={formPonctuelle}
          >

            <Row justify="space-between">

              <Col xs={24} lg={11}>

                <Form.Item
                  label="Contrat"
                  name="contrat"
                  rules={[{ required: true, message: 'Veuillez choisir un contrat!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Rechercher un contrat"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contrats.map(contrat => (
                      <Option key={contrat.UUID} value={contrat.UUID}>{contrat.Enfant["Nom complet"]}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={11}>
                <Form.Item
                  label="Date"
                  name="date"
                  rules={[{ required: true, message: 'Veuillez sélectionner une date!' }]}
                >
                  <DatePicker style={{ width: ' 100%' }} format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} lg={11}>
                <Form.Item
                  label="Heure de début"
                  name="heureDebut"
                  rules={[{ required: true, message: 'Veuillez sélectionner une heure de début!' }]}
                >
                  <TimePicker style={{ width: ' 100%' }} format="HH:mm" showNow={false} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={11}>

                <Form.Item

                  label="Heure de fin"
                  name="heureFin"
                  rules={[{ required: true, message: 'Veuillez sélectionner une heure de fin!' }]}
                >
                  <TimePicker style={{ width: ' 100%' }} format="HH:mm" showNow={false} />
                </Form.Item>
              </Col>
              <Form.Item>
                <Button icon={<SaveOutlined />} type="primary" htmlType="submit" loading={loading} className="form-button">
                  Enregistrer
                </Button>
              </Form.Item>


            </Row>

          </Form>

        </div>
      </TabPane>
      <TabPane tab="Planification hebdomadaire" key="2">
        <div className="form-container">
          <Form
            name="contrat-hebdo"
            layout="vertical"
            onFinish={onFinishPlanificationHebdomadaire}
            form={formHebdomadaire}
          >

            <Row>
              <Col span={24}>


                <Form.Item
                  label="Contrat"
                  name="contratHebdomadaire"
                  rules={[{ required: true, message: 'Veuillez choisir un contrat!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Rechercher un contrat"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contrats.map(contrat => (
                      <Option key={contrat.UUID} value={contrat.UUID}>{contrat.Enfant["Nom complet"]}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>

                <Form.Item
                  label="Période"
                  name={['periode', 'dateDebut']}
                  rules={[{ required: true, message: 'Veuillez sélectionner une date de début!' }]}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Form.Item>

              </Col>
            </Row>

            <Form.Item
              label="Jours"
              name={['periode', 'jours']}
              rules={[{ required: true, message: 'Veuillez sélectionner au moins un jour!' }]}
            >
              <Checkbox.Group options={['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']} />
            </Form.Item>
            <Row justify="space-between">
              <Col xs={24} lg={11}>
                <Form.Item
                  label="Heure de début"
                  name="heureDebutHebdomadaire"
                  rules={[{ required: true, message: 'Veuillez sélectionner une heure de début!' }]}
                >
                  <TimePicker style={{ width: ' 100%' }} format="HH:mm" showNow={false} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={11}>

                <Form.Item

                  label="Heure de fin"
                  name="heureFinHebdomadaire"
                  rules={[{ required: true, message: 'Veuillez sélectionner une heure de fin!' }]}
                >
                  <TimePicker style={{ width: ' 100%' }} format="HH:mm" showNow={false} />
                </Form.Item>
              </Col>

            </Row>
            <Form.Item>
              <Button icon={<SaveOutlined />} type="primary" htmlType="submit" loading={loading} className="form-button">
                Enregistrer
              </Button>
            </Form.Item>


          </Form>
        </div>
      </TabPane>

    </Tabs >

  );
};

export default PlanningModalAdd;
