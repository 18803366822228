import React from "react";
import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { Download } from "../DocumentsApi";

const DownloadFile = ({ url }) => {
  const handleClick = () => {
    Download(url);
  };

  // custom Style
  return (
    <>
      <DownloadOutlined style={{ fontSize: "20px" }} onClick={handleClick} />
      <FileOutlined
        style={{ fontSize: "23px", color: "#4E89FF" }}
        onClick={handleClick}
      />
    </>
  );
};

export default DownloadFile;
