import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import { motion } from "framer-motion";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Routes from "./Routes/Routes";
import { Layout } from "antd";

import { useAuth } from "./context/AuthContext";

const { Content } = Layout;

const router = createBrowserRouter(Routes);

const Main = () => {
  const [isHeaderOpen, setIsHeaderOpen] = useState();
  const [firstAnimate, setFirstAnimate] = useState(false);
  const [animate, setAnimate] = useState("0");

  useEffect(() => {
    // Les animations ne se font pas au chargement de la page
    if (isHeaderOpen || firstAnimate) {
      setFirstAnimate(true);
      setAnimate("250px"); // Taille du header
    }
  }, [isHeaderOpen]);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    console.log("L'utilisateur est connecté :", isAuthenticated);
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Layout className="body" style={{ minHeight: "100vh" }}>
        <Layout>
         
            {/* Déclaration du header avec la gestion du bouton ouvert ou pas */}
            {isAuthenticated && <Header setIsHeaderOpen={setIsHeaderOpen} />}
            {/* Toutes les balises "motion" servent à l'animation et à la mise en page lorsque le header est ouvert */}
            <div id="pageContent">
              <motion.div
                className="content"
                initial={isHeaderOpen ? { x: "O" } : { x: animate }}
                animate={isHeaderOpen ? { x: animate } : { x: "0px" }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 30,
                  delay: isHeaderOpen ? 0 : 0.6,
                }}
              >
                <motion.div
                  initial="closed"
                  animate={isHeaderOpen ? "open" : "closed"}
                  variants={{
                    open: { width: "calc(85% - 4px)" },
                    closed: { width: "calc(100% - 4px)" },
                  }}
                  transition={{ delay: isHeaderOpen ? 0 : 0.6 }}
              >
                <div className={isAuthenticated ?  "bodyContent" : ""}>
                  <RouterProvider router={router} />
                </div>
                </motion.div>
              </motion.div>
            </div>
      
        </Layout>
        {isAuthenticated && <Sidebar />}
      </Layout>
    </div>
  );
};

export default Main;
