import React from 'react'
import ForgotPasswordPassword from '../../../components/Auth/ForgotPassword/ForgotPasswordPasswordForm/ForgotPasswordPasswordForm'

const ForgotPassword = () => {
    return (
        <div>

            <ForgotPasswordPassword/>


        </div>
    )
}

export default ForgotPassword