import React, { useState } from 'react';
import { Card, Modal, Row, Col, Form, Input, DatePicker, Button, Select, Divider } from 'antd';
import { UserOutlined, ProfileOutlined, EnvironmentOutlined, MailOutlined, CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { GiPalmTree } from "react-icons/gi";
import { useAuth } from '../../../context/AuthContext';

import './ContratInfo.css';

export const ContratInfo = ({ contrat }) => {
    const separatorStyle = {
        borderTop: `2px solid ${contrat.Couleur}`,
    };

    console.log(contrat)
    const iconStyle = {
        color: contrat.Couleur,
    };

    return (
        <div>
            <Row gutter={[16, 16]} justify="start" align="middle">
                <Col>
                    <img src={contrat.Photo} className="enfant-photo" alt={contrat.Enfant['Nom complet']} />
                </Col>
                <Col>
                    <h5 style={{ marginTop: '15px' }}>{contrat.Enfant['Nom complet']}</h5>
                </Col>
            </Row>

            <hr style={separatorStyle} />

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Enfant :</h5>
                        <p><UserOutlined style={iconStyle} /> <strong>Date de naissance :</strong> {contrat.Enfant["Date de naissance"]}</p>
                        <p><CalendarOutlined style={iconStyle} /> <strong>Age :</strong> {contrat.Enfant.Age}</p>
                        <p><ProfileOutlined style={iconStyle} /> <strong>Parent :</strong> {contrat.Enfant.Parent}</p>
                        <p><EnvironmentOutlined style={iconStyle} /> <strong>Adresse :</strong> {contrat.Enfant.Adresse}</p>
                        <p><MailOutlined style={iconStyle} /> <strong>Email :</strong> {contrat.Enfant.Email}</p>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Assistante Maternelle :</h5>
                        <p><ProfileOutlined style={iconStyle} /> <strong>Nom :</strong> {contrat["Assistante maternelle"]['Nom complet']}</p>
                        <p><MailOutlined style={iconStyle} /> <strong>Email :</strong> {contrat["Assistante maternelle"].Email}</p>
                        <p><EnvironmentOutlined style={iconStyle} /> <strong>Adresse :</strong> {contrat["Assistante maternelle"].Adresse}</p>
                        <p><UserOutlined style={iconStyle} /> <strong>Téléphone :</strong> {contrat["Assistante maternelle"].Téléphone}</p>
                    </div>
                </Col>
            </Row>

            <hr style={separatorStyle} />

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Détails du Contrat :</h5>
                        <p><InfoCircleOutlined style={iconStyle} /> <strong>Statut :</strong> {contrat.Statut}</p>
                        <p><CalendarOutlined style={iconStyle} /> <strong>Date de début :</strong> {contrat["Date de début"]}</p>
                        <p><CalendarOutlined style={iconStyle} /> <strong>Date de fin :</strong> {contrat["Date de fin"]}</p>
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="enfant-details">
                        <h5>Absences prévues au contrat : </h5>
                        <p><GiPalmTree style={iconStyle} /></p>
                        <ul className='listeAbsences'>
                            {contrat["Absences prévues au contrat"].map((absence, index) => (
                                <li key={index}><p>{absence}</p></li>
                            ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
