import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { useAuth } from '../../context/AuthContext';

const ResumeAbscence = () => {
  const { token } = useAuth();
  const [absenceData, setAbsenceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.uama.fr/v1.0/planning/absence-contrat", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setAbsenceData(data);
      } catch (error) {
        console.error("Error fetching absence data:", error);
        // Handle error, maybe show a message to the user
      }
    };

    fetchData();
  }, [token]);

  const columns = [
    {
      title: "Enfant",
      dataIndex: "Enfant",
      key: "Enfant",
    },
    {
      title: "Date début",
      dataIndex: "Absences",
      key: "Date début",
      render: (absences) => absences.map((absence, index) => (
        <div key={index}>{absence["Date début"]}</div>
      )),
    },
    {
      title: "Date fin",
      dataIndex: "Absences",
      key: "Date fin",
      render: (absences) => absences.map((absence, index) => (
        <div key={index}>{absence["Date fin"]}</div>
      )),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={absenceData} pagination={{ pageSize: 3 }} size={"small"} />
    </div>
  );
};

export default ResumeAbscence;
