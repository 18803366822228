import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";

// Création d'un contexte pour gérer l'authentification
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loginError, setLoginError] = useState(null);

  // État pour stocker les informations de l'utilisateur
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser
      ? JSON.parse(storedUser)
      : {
          UUID: "",
          "Nom complet": "",
          "Date de naissance": "",
          Age: 0,
          Roles: [],
          Email: "",
          Téléphone: "",
          "Statut du compte": "",
          "Création du compte": "",
          "Dernière connexion": "",
          "Date de fin d'agrément": "",
          "Date de fin assurance habitation": "",
          "Date de fin assurance voiture": "",
          notifications: [],
          nbr_notification: 0,
          token: "",
        };
  });

  // État pour suivre l'état de l'authentification
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  // État pour stocker le token et le temps de connexion
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loginTime, setLoginTime] = useState(localStorage.getItem("loginTime"));

  // Durée d'expiration du token en millisecondes (2 heures)
  const tokenExpirationTime = 7200000;

  // Fonction de connexion
  const login = () => {
    setIsAuthenticated(true);
    setLoginTime(new Date().getTime()); // Enregistre le temps de connexion actuel
  };

  // Fonction de connexion avec l'API
  const loginWithApi = async (email, password) => {
    try {
      const response = await axios.post("https://api.uama.fr/auth/connexion", {
        email,
        password,
      });

      if (response.statusText === "OK") {
        setIsAuthenticated(true);
        setUser(response.data.user);
        setToken(response.data.token);
        setLoginTime(new Date().getTime()); // Enregistre le temps de connexion actuel
      } else {
        console.error("Authentification échouée");
      }
    } catch (error) {
      if (error === "AxiosError: Network Error") {
        setLoginError(
          "Une erreur s'est produite lors de la connexion (erreur serveur)."
        );
      } else {
        console.error(error.response.data.message);
        setLoginError(
          error.response.data.message ||
            "Une erreur s'est produite lors de la connexion."
        );
      }
    }
  };

  // Fonction de déconnexion
  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setToken("");
    setLoginTime(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
  };

  // Effet pour mettre à jour le stockage local lorsque l'état change
  useEffect(() => {
    localStorage.setItem("isAuthenticated", isAuthenticated);

    if (isAuthenticated) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("loginTime", loginTime);
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("loginTime");
    }
  }, [isAuthenticated, user, token, loginTime]);

  // Effet pour vérifier l'expiration du token
  useEffect(() => {
    const checkTokenExpiration = () => {
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - loginTime;

      // Si le temps écoulé est supérieur à la durée d'expiration du token
      if (isAuthenticated && currentTime - loginTime > tokenExpirationTime) {
        logout();
        // Affiche un message d'erreur à l'utilisateur
        message.error("Token expiré, merci de vous reconnecter");
      }
    };

    checkTokenExpiration();

    // Configuration d'une minuterie pour vérifier périodiquement l'expiration du token
    const expirationTimer = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Vérification toutes les 60 secondes

    // Nettoyage de la minuterie lors du démontage du composant
    return () => clearInterval(expirationTimer);
  }, [loginTime, setIsAuthenticated, setUser, setToken]);

  // Fournit le contexte et les fonctions aux composants enfants
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        loginWithApi,
        loginError,
        user,
        setUser,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Fonction hook pour utiliser le contexte d'authentification dans les composants enfants
export const useAuth = () => {
  return useContext(AuthContext);
};
