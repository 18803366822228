import React, { useState, useEffect } from "react";
import {
  Table,
  InputNumber,
  Row,
  Col,
  Card,
  Badge,
  Button,
  message,
} from "antd";
import { SaveOutlined, FilePdfOutlined } from "@ant-design/icons";

import "./ImpotsTab.css";
import ViewPDF from "./ConvertPDF";

const ImpotsTab = ({
  nomEnfant,
  impots,
  statusModal,
  selectedContrat,
  year,
  token,
  refresh,
}) => {
  const [prixAvantageNature, setPrixAvantageNature] = useState(0);
  const [impotsData, setImpotsData] = useState(impots.data);
  const [totals, setTotals] = useState({
    mois: "TOTAL",
    salaire: 0,
    avantagesNature: 0,
    plus8heures: 0,
    moins8heures: 0,
    heureComplementaire: 0,
  });

  

  const columns = [
    {
      title: "Mois",
      dataIndex: "mois",
      key: "mois",
      align: "center",
      className: "col-mois",
    },
    {
      title: "Salaire net imposable",
      dataIndex: "salaire",
      key: "salaire",
      align: "center",
      className: "col-salaire",
      render: (_, record) =>
        statusModal === "edit" ? (
          <InputNumber
            className="InputSalaire"
            defaultValue={record.salaire}
            onChange={(value) =>
              handleInputChange(value, "salaire", record.mois)
            }
          />
        ) : (
          record.salaire
        ),
    },
    {
      title: "Avantages en nature",
      dataIndex: "avantagesNature",
      key: "avantagesNature",
      className: "col-avantagesNature",
      align: "center",
      render: (_, record) => {
        if (statusModal === "edit") {
          const avantageNature = parseFloat(record.repas) * prixAvantageNature;
          return isNaN(avantageNature) ? 0 : avantageNature.toFixed(2);
        } else {
          return record.repas;
        }
      },
    },
    {
      title: "Nombre de jours réels d'accueil de 8 heures et plus",
      dataIndex: "plus8heures",
      key: "plus8heures",
      className: "col-plus8heures",
      align: "center",
      render: (_, record) => record.plus8heures,
    },
    {
      title: "Nombre d'heures réelles d'accueil de moins de 8 heures",
      dataIndex: "moins8heures",
      key: "moins8heures",
      className: "col-moins8heures",
      align: "center",
      render: (_, record) => record.moins8heures,
    },
    {
      title: "Nombre d'heures réelles complémentaires",
      dataIndex: "heureComplementaire",
      key: "heureComplementaire",
      className: "col-heureComplementaire",
      align: "center",
      render: (_, record) => record.heureComplementaire,
    },
  ];

  // APPeler quand valeur change dans colonne salaire
  const handleInputChange = (value, field, mois) => {
    // Trouver l'index de l'élément correspondant dans impotsData
    const index = impotsData.findIndex((item) => item.mois === mois);
    if (index !== -1) {
      // Créer une copie de l'élément modifié
      const updatedItem = { ...impotsData[index] };
      // Mettre à jour la valeur du champ spécifié
      updatedItem[field] = value;
      // Mettre à jour impotsData avec la nouvelle valeur
      const updatedImpotsData = [...impotsData];
      updatedImpotsData[index] = updatedItem;
      setImpotsData(updatedImpotsData);
    }
  };

  useEffect(() => {
    updateTotal();
  }, [impotsData, prixAvantageNature]);

  const updateTotal = () => {
    const totalSalaire = impotsData.reduce(
      (acc, item) => acc + parseFloat(item.salaire || 0),
      0
    );
    const totalAvantagesNature =
      statusModal === "edit"
        ? impotsData.reduce((acc, item) => {
            const avantageNature = parseFloat(item.repas) * prixAvantageNature;
            return isNaN(avantageNature) ? acc : acc + avantageNature;
          }, 0)
        : impotsData.reduce(
            (acc, item) => acc + parseFloat(item.repas || 0),
            0
          );
    const totalPlus8heures = impotsData.reduce(
      (acc, item) => acc + parseFloat(item.plus8heures || 0),
      0
    );
    const totalMoins8heures = impotsData.reduce(
      (acc, item) => acc + parseFloat(item.moins8heures || 0),
      0
    );
    const totalHeureComplementaire = impotsData.reduce(
      (acc, item) => acc + parseFloat(item.heureComplementaire || 0),
      0
    );

    const newTotals = {
      mois: "TOTAL",
      salaire: totalSalaire.toFixed(2),
      avantagesNature: totalAvantagesNature.toFixed(2),
      plus8heures: totalPlus8heures.toFixed(2),
      moins8heures: totalMoins8heures.toFixed(2),
      heureComplementaire: totalHeureComplementaire.toFixed(2),
    };

    setTotals(newTotals);
  };

  const handlePrixAvantageNatureChange = (value) => {
    setPrixAvantageNature(value);
  };

  const dataSource = impotsData.map((item, index) => ({
    ...item,
    key: index,
  }));

  // Convertir les valeurs en float
  const salaireTotal = parseFloat(totals.salaire);
  const avantagesNatureTotal = parseFloat(totals.avantagesNature);
  const AbbatementPlus = parseFloat(totals.plus8heures);
  const AbbatementMoins = parseFloat(totals.moins8heures);

  // Calculez le total de "Salaire + avantages en nature"
  const salaireAvantagesTotal = (salaireTotal + avantagesNatureTotal).toFixed(
    2
  );
  const montantDeclarer = (
    salaireTotal +
    avantagesNatureTotal -
    AbbatementPlus -
    AbbatementMoins
  ).toFixed(2);

  const onSaveClick = async () => {
    const mappedData = impotsData.map((item, index) => ({
      ...item,
      mois: index + 1, // Index + 1 pour représenter les mois à partir de 1
    }));

    const postData = {
      contrat: selectedContrat,
      year: year,
      data: mappedData,
    };

    try {
      // Envoi de la requête à l'API
      const response = await fetch(
        "https://api.uama.fr/v1.0/gestion-fiscale/saisie",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        message.success(data.message);
        refresh();
      } else {
        console.log();
        const errorData = await response.json(); // Récupérer les données d'erreur du serveur
        message.error(errorData.message); // Afficher le message d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du contrat :", error);
      console.log(error);
    }
  };

  const deleteImpots = async () => {
    try {
      const response = await fetch(
        `https://api.uama.fr/v1.0/gestion-fiscale/${selectedContrat}?annee=${year}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        message.success(data.message);
        refresh();
      } else {
        const errorData = await response.json();
        message.error(errorData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'impôt :", error);
      message.error("Erreur lors de la suppression de l'impôt.");
    }
  };


  const isMobile = window.innerWidth <= 600;
  const [sizeTab, setSizeTab] = useState();
  useEffect(() => {    
    const isLarge = window.innerHeight > 850;
    setSizeTab(isLarge ? 800 : 350);
    console.log(sizeTab)
  },[])




  return (
    <div>
      {statusModal === "delete" ? (
        <>
        <p>Êtes-vous sûr de vouloir supprimer cet impôt ?</p>
          <Button type="primary" onClick={deleteImpots} danger>
          Supprimer l'impôt
          </Button>
        </>
      ) : (
    <div>
      <div>
        {statusModal === "edit" && (
          <InputNumber
            className="inputNumberImpots"
            addonBefore="Prix d'avantage en nature"
            defaultValue={0}
            onChange={handlePrixAvantageNatureChange}
            suffix="€"
          />
        )}
      </div>
      <Row justify="space-between">
        <Col xs={24} sm={15}>
          <Table
            className="tableauImpots"
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            size="small"
            bordered={true}
            scroll={!isMobile? { y: sizeTab } : { x: 180 }}
            
            footer={() => (
              <Row className="impots-row" justify="center">
                <Col span={4}>
                  <p className="impots-label">TOTAL</p>
                </Col>
                <Col span={4}>
                  <p className="impots-value">{totals.salaire} €</p>
                </Col>
                <Col span={4}>
                  <p className="impots-value">{totals.avantagesNature} €</p>
                </Col>
                <Col span={4}>
                  <p className="impots-value">{totals.plus8heures} €</p>
                </Col>
                <Col span={4}>
                  <p className="impots-value">{totals.moins8heures} €</p>
                </Col>
                <Col span={4}>
                  <p className="impots-value">{totals.heureComplementaire} €</p>
                </Col>
              </Row>
            )}
          />
        </Col>

        <Col xs={24} sm={8}>
          <Card className="impots-card">
            <Row className="impots-row">
              <Col span={14}>
                <p className="impots-label">
                  <Badge color="#f29eff" /> Salaire + <Badge color="#90ee90" />{" "}
                  Avantages en nature
                </p>
              </Col>
              <Col span={10}>
                <p className="impots-value">{salaireAvantagesTotal} €</p>
              </Col>
            </Row>
            <Row className="impots-row">
              <Col span={14}>
                <p className="impots-label">
                  <Badge color="#ff8b7e" /> - Abbatement 8h+{" "}
                </p>
              </Col>
              <Col span={10}>
                <p className="impots-value">{totals.plus8heures} €</p>
              </Col>
            </Row>
            <Row className="impots-row">
              <Col span={14}>
                <p className="impots-label">
                  <Badge color="#f1e572" /> - Abbatement 8h-{" "}
                </p>
              </Col>
              <Col span={10}>
                <p className="impots-value">{totals.moins8heures} €</p>
              </Col>
            </Row>
            <Row className="impots-row">
              <Col span={14}>
                <p className="impots-label montantDeclarer">
                  Montant à déclarer
                </p>
              </Col>
              <Col span={10}>
                <p className="impots-value montantDeclarer">
                  {montantDeclarer} €
                </p>
              </Col>
            </Row>
            {statusModal === "edit" ? (
              <Button
                icon={<SaveOutlined />}
                type="primary"
                onClick={onSaveClick}
                danger
              >
                Enregistrer
              </Button>
            ) : (
              <ViewPDF year={year} nomEnfant={nomEnfant} totals={totals} impotsData={impotsData} montantDeclarer={montantDeclarer}/>
            )}
          </Card>
        </Col>
      </Row>
          </div>
      )}
    </div>
  );
};

export default ImpotsTab;
