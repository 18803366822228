import React from "react";
import { useState } from "react";
import { Modal, Input, Upload, message } from "antd";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { EditFichier, EditRepertoire } from "../DocumentsApi";

export const EditFolder = ({ nodeData, token, forceUpdate }) => {
  const { name, id } = nodeData;

  const [isRenameModalVisible, setRenameModalVisible] = useState(false);
  const [newFolderName, setNewFolderName] = useState(name);

  const handleRename = () => {
    EditRepertoire({
      nom: newFolderName,
      idFolder: id,
      token: token,
      forceUpdate: forceUpdate,
    });

    setRenameModalVisible(false);
  };

  const handleCancel = () => {
    setRenameModalVisible(false);
  };

  // click edit
  const handleClick = () => {
    // Fenetre modal
    setRenameModalVisible(true);
  };

  // Chaque changement du champs de saisie
  const handleInputChange = (e) => {
    setNewFolderName(e.target.value);
  };

  return (
    <>
      <EditOutlined
        style={{ fontSize: "25px", color: "#4E89FF" }}
        onClick={handleClick}
      />

      {/* Fenêtre modale de renommage */}
      <Modal
        title={`Renommer le dossier "${name}"`}
        onOk={handleRename}
        onCancel={handleCancel}
        open={isRenameModalVisible}
      >
        <p>Voulez-vous vraiment renommer le dossier ?</p>
        <Input
          value={newFolderName}
          onChange={handleInputChange}
          placeholder="Nouveau nom du dossier"
        />
      </Modal>
    </>
  );
};

export const EditFile = ({ nom, id, token, forceUpdate }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [fileList, setFileList] = useState([]);

  //Gestuin extension fichier
  nom = nom.replace(/\..+$/, "");

  const [newFileName, setNewFileName] = useState(nom);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleRename = () => {
    EditFichier({
      nom: newFileName,
      idFile: id,
      file: fileList[0],
      token: token,
      forceUpdate: forceUpdate,
    });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Chaque changement du champs de saisie
  const handleInputChange = (e) => {
    setNewFileName(e.target.value);
  };

  const onFileChange = (info) => {
    // Gérer les changements de fichier lors de la sélection dans l'Upload
    const uploadedFile = info.file.originFileObj; // Access the File object

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    setFileList([info.file]); // Set fileList with the updated File object
  };

  return (
    <>
      <EditOutlined
        style={{ fontSize: "25px", color: "#4E89FF" }}
        onClick={showModal}
      />

      <Modal
        title={`Modifier le fichier "${nom}"`}
        open={isModalVisible}
        onOk={handleRename}
        onCancel={handleCancel}
      >
        <p>Veuillez saisir un nouveau nom pour le fichier :</p>
        <Input
          value={newFileName}
          onChange={handleInputChange}
          placeholder="Nouveau nom du fichier"
        />
        <br />
        <br />
        <p>Sélectionnez un seul nouveau fichier en le glissant-déposant :</p>
        <Upload.Dragger
          onChange={onFileChange}
          fileList={fileList}
          beforeUpload={() => false} // Prevent automatic upload, as it's handled manually in handleOk
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">
            Cliquez ou glissez-déposez pour télécharger
          </p>
        </Upload.Dragger>
      </Modal>
    </>
  );
};
