// ForgotPasswordEmail.jsx
import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom"; 
import { MailOutlined } from "@ant-design/icons";
import axios from "axios"; // Importez la bibliothèque axios


const ForgotPasswordEmailForm = (props) => {
  const { handleDisplayForgot } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);



  const onFinish = async (values) => {
    setLoading(true);

    try {
      // Envoyer une requête POST à votre API avec l'adresse e-mail
      const response = await axios.post("https://api.uama.fr/password/reset/request", {
        email: values.email,
      });

      // Vérifier la réponse de l'API
      if (response.status === 200) {
        message.success(
          "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail."
        );
        // Redirigez l'utilisateur vers la page de connexion après la demande réussie.
        navigate("/login");
      } else {
        message.error("Erreur lors de la demande de réinitialisation du mot de passe.");
      }
    } catch (error) {
      message.error("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      name="ForgotPasswordEmail"
      size="large"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <h6>Merci de saisir votre adresse email</h6>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Merci de saisir une adresse email valide",
          },
          {
            type: "email",
            message: "Merci de saisir une adresse email valide",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>

      <Form.Item>
        <a className="login-form-forgot" onClick={handleDisplayForgot}>
          Se connecter
        </a>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="forgot-password-email-button"
          loading={loading}
        >
          Réinitialiser le mot de passe
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgotPasswordEmailForm;
