import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Select, Row, Col, message } from 'antd';

import { useDocuments } from '../../context/DocumentsContext';
import { useAuth } from '../../context/AuthContext';

import './DocumentsPage.css';
import './DocumentsBtn.css';

const { Option } = Select;

const DocumentsPage = () => {
  const [idSelectedEnfant, setIdSelectedEnfant] = useState(null);
  const [nameSelectedEnfant, setNameSelectedEnfant] = useState(null);
  const { documents } = useDocuments();
  const { user } = useAuth();

  useEffect(() => {
    if (documents && documents.contient) {
      // Assuming documents.contient is an array, you may need to adjust accordingly
      const defaultDocument = documents.contient.find(document => document.id === idSelectedEnfant);
      if (defaultDocument) {
        setNameSelectedEnfant(defaultDocument["nom du répertoire"]);
      }
    }
  }, [documents, idSelectedEnfant]);

  const handleEnfantChange = (value) => {
    setIdSelectedEnfant(value);
  };

  const renderEnfantOptions = () => {
    if (!documents || !documents.contient) {
      return null;
    }

    const filteredContient = documents.contient.filter(document =>
      document["nom du répertoire"] !== "Global" && document["nom du répertoire"] !== "Personnel"
    );

    return filteredContient.map((document) => (
      <Option key={document.id} value={document.id}>
        {document["nom du répertoire"]}
      </Option>
    ));
  };

  return (
    <div className='btnDocumentContainer'>
      <Link to="/documents/documentsAll" className="button button--piyo">
        <div className="button__wrapper">
          <span className="button__text">Documents globaux</span>
        </div>
        <div className="characterBox">
          <div className="character wakeup">
            <div className="character__face"></div>
          </div>
          <div className="character wakeup">
            <div className="character__face"></div>
          </div>
          <div className="character">
            <div className="character__face"></div>
          </div>
        </div>
      </Link>

      {user.Roles.includes("ROLE_ASSISTANTE_MATERNELLE") && (
        <Link to="/documents/documentsPerso" className="button button--hoo">
          <div className="button__wrapper">
            <span className="button__text">Documents personnels</span>
          </div>
          <div className="characterBox">
            <div className="character wakeup">
              <div className="character__face"></div>
              <div className="charactor__face2"></div>
              <div className="charactor__body"></div>
            </div>
            <div className="character wakeup">
              <div className="character__face"></div>
              <div className="charactor__face2"></div>
              <div className="charactor__body"></div>
            </div>
            <div className="character">
              <div className="character__face"></div>
              <div className="charactor__face2"></div>
              <div className="charactor__body"></div>
            </div>
          </div>
        </Link>
      )}

      <Link to={`/documents/DocumentsParents/${idSelectedEnfant}`} className="button button--pen"
        onClick={(e) => {
          if (!idSelectedEnfant) {
            e.preventDefault();
            message.error("Merci de sélectionner un répertoire.");
          }
        }}>
        <div className="button__wrapper">
          <span className="button__text">{nameSelectedEnfant || '...'}</span>
        </div>
        <div className="characterBox">
          <div className="character wakeup">
            <div className="character__face"></div>
            <div className="charactor__face2"></div>
          </div>
          <div className="character wakeup">
            <div className="character__face"></div>
            <div className="charactor__face2"></div>
          </div>
          <div className="character">
            <div className="character__face"></div>
            <div className="charactor__face2"></div>
          </div>
        </div>
      </Link>

      <Select
        showSearch
        className="selectEnfant"
        placeholder="Sélectionner un répertoire"
        onChange={handleEnfantChange}
        value={idSelectedEnfant}
      >
        {renderEnfantOptions()}
      </Select>
    </div>
  );
};

export default DocumentsPage;
