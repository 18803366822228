import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { message, Select, List } from "antd";
import { motion } from "framer-motion";
import ImpotsCard from "../../components/Impots/ImpotsCard/ImpotsCard";
import Loading from "../../components/Loading/Loading";

import "./ImpotsPage.css"

const { Option } = Select;

const ImpotsPage = () => {
  const { token } = useAuth();
  const [contrats, setContrats] = useState([]);
  const [impotsData, setImpotsData] = useState({});
  const [selectedContrat, setSelectedContrat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchContrats = async () => {
      try {
        const response = await fetch(
          "https://api.uama.fr/v1.0/contrat/index?limit=inf",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setContrats(data.contrats);
        } else {
          const errorData = await response.json();
          message.error(errorData.message);
          throw new Error("Erreur lors de la récupération des contrats");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchContrats();
  }, [token]);

  const fetchImpots = async () => {
    if (!selectedContrat) return;

    setIsLoading(true);

    try {
      const response = await fetch(
        `https://api.uama.fr/v1.0/gestion-fiscale/${selectedContrat}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setImpotsData(data);
      } else {
        const errorData = await response.json();
        message.error(errorData.message);
        throw new Error("Erreur lors de la récupération des contrats");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchImpots();
  }, [selectedContrat, token]);

  const refresh = () => {
    // Appeler à nouveau la fonction de récupération des impôts
    fetchImpots();
  };

  const isMobile = window.innerWidth <= 1000;
  return (
    <div>
      <Select
        style={{ width: isMobile ? "100%" : 200, marginBottom: 10 }}
        placeholder="Sélectionner un contrat"
        onChange={(value) => setSelectedContrat(value)}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={isLoading} // Désactiver le Select pendant le chargement
      >
        {contrats.map((contrat) => (
          <Option key={contrat.UUID} value={contrat.UUID}>
            {contrat["Enfant"]["Nom complet"]}
          </Option>
        ))}
      </Select>

      {isLoading ? (
        <Loading />
      ) : (
        <List
          grid={{
            gutter: 30,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 4,
            xxl: 4,
          }}
          dataSource={Object.keys(impotsData)}
          renderItem={(key, index) =>
            key !== "enfant" &&
            key !== "adresse" && (
              <List.Item key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.25 }}
                >
                  <ImpotsCard
                    nomEnfant={
                      contrats.find(
                        (contrat) => contrat.UUID === selectedContrat
                      )["Enfant"]["Nom complet"]
                    }
                    refresh={refresh}
                    impots={impotsData[key]}
                    selectedContrat={selectedContrat}
                  />
                </motion.div>
              </List.Item>
            )
          }
        />
      )}
    </div>
  );
};

export default ImpotsPage;
