import { FolderAddOutlined } from "@ant-design/icons";
import { useState } from "react";

import { Modal, Input } from "antd";

const AddFolder = ({ nom, parentId, token, forceUpdate }) => {
  const { AddRepertoire } = require("../DocumentsApi.js");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [folderName, setFolderName] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  // custom event handler
  const handleAddFolder = () => {
    AddRepertoire({
      nom: folderName,
      parentId: parentId,
      token: token,
      forceUpdate: forceUpdate,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Chaque changement du champs de saisie
  const handleInputChange = (e) => {
    setFolderName(e.target.value);
  };

  return (
    <>
      <FolderAddOutlined
        style={{ fontSize: "25px", color: "#4E89FF" }}
        onClick={showModal}
      />

      <Modal
        title={`Ajouter un dossier`}
        onOk={handleAddFolder}
        onCancel={handleCancel}
        open={isModalVisible}
      >
        <p>Merci de saisir le nom du dossier </p>
        <Input
          value={folderName}
          onChange={handleInputChange}
          placeholder="Nom du dossier"
        />
      </Modal>
    </>
  );
};

export default AddFolder;
