import React from "react";
import { useState } from "react";
import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export const DeleteFolder = ({ name, id, token, forceUpdate }) => {
  const { DeleteRepertoire } = require("../DocumentsApi.js");
  const [isModalVisible, setIsModalVisible] = useState(false);

  // custom event handler
  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleConfirmDelete = () => {
    // Si l'utilisateur confirme, procéder à la suppression
    DeleteRepertoire({
      idRepertoire: id,
      token: token,
      forceUpdate: forceUpdate,
    });

    setIsModalVisible(false);
  };

  const handelCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <DeleteOutlined
        style={{ fontSize: "25px", color: "#4E89FF" }}
        onClick={handleClick}
      />

      <Modal
        title={`Supprimer le dossier "${name}"`}
        open={isModalVisible}
        onOk={handleConfirmDelete}
        onCancel={handelCancel}
      >
        <p>Voulez-vous vraiment supprimer le dossier "{name}"?</p>
      </Modal>
    </>
  );
};

export const DeleteFile = ({ name, id, token, forceUpdate }) => {
  const { DeleteFichier } = require("../DocumentsApi.js");

  const [isModalVisible, setIsModalVisible] = useState(false);
  // custom event handler
  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleConfirmDelete = () => {
    // Si l'utilisateur confirme, procéder à la suppression
    DeleteFichier({
      idFichier: id,
      token: token,
      forceUpdate: forceUpdate,
    });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <DeleteOutlined
        style={{ fontSize: "25px", color: "#4E89FF" }}
        onClick={handleClick}
      />

      <Modal
        title={`Supprimer le fichier "${name}"`}
        open={isModalVisible}
        onOk={handleConfirmDelete}
        onCancel={handleCancel}
      >
        <p>Voulez-vous vraiment supprimer le fichier "{name}"?</p>
      </Modal>
    </>
  );
};
