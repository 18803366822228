import React, { useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Steps,
  Divider,
  message,
  Alert,
} from "antd";
import "../SignInPage.css";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const { Item } = Form;
const { Step } = Steps;

const SignInPage = () => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();

  //FormData pour stocker les valeur des form pour les différentes pages.
  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values }); // Merge values into formData
        setCurrentStep(currentStep + 1);
      })
      .catch((error) => {
        console.log("Validation Error:", error);
        message.error("Veuillez remplir correctement tous les champs.");
      });
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  //values pour les 3 valeurs de la dernières parties
  const onFinish = async (values) => {
    try {
      const response = await axios.post(
        "https://api.uama.fr/auth/inscription/assistanteMaternelle",
        {
          dateFinAgrement: values.dateFinAgrement.format("YYYY-MM-DD"),
          dateFinAssuranceHabitation:
            values.dateFinAssuranceHabitation.format("YYYY-MM-DD"),
          dateFinAssuranceVoiture:
            values.dateFinAssuranceVoiture.format("YYYY-MM-DD"),
          email: formData.email,
          numeroTelephone: formData.numeroTelephone,
          dateNaissance: formData.dateNaissance.format("YYYY-MM-DD"),
          password: formData.password,
          prenom: formData.prenom,
          nom: formData.nom,
          adresse: formData.adresse,
          codePostal: formData.codePostal,
          ville: formData.ville,
          pays: formData.pays,
        }
      );
      console.log("API Response:", response.data);
      message.success("Inscription réussi");
      navigate("/");
      // Handle success or navigate to the next step as needed
    } catch (error) {
      console.error("API Error:", error.response.data.message);
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="signIn-container">
      <Alert
        message="Attention : Cette page est destinée aux assistantes maternelles uniquement."
        type="warning"
        showIcon
        className="alert-Asmat"
      />

      <Form
        form={form}
        className="signIn-form"
        onFinish={onFinish}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 24 }}
      >
        {/* Etape 1: Identifiants */}
        {currentStep === 0 && (
          <>
            <Form.Item
              name="email"
              label="Email"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une adresse email valide",
                },
                {
                  type: "email",
                  message: "Merci de saisir une adresse email valide",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Mot de passe"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un mot de passe",
                },
                {
                  min: 8,
                  message: "Le mot de passe doit avoir au moins 8 caractères",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="passwordConfirmation"
              label="Confirmation du mot de passe"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Merci de confirmer votre mot de passe",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Les mots de passe ne correspondent pas ")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}

        {/* Etape 2: Informations */}
        {currentStep === 1 && (
          <>
            <Form.Item
              name="nom"
              label="Nom"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un nom",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="prenom"
              label="Prénom"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un prénom",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="numeroTelephone"
              label="Numéro de téléphone"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un numéro de téléphone",
                },
                {
                  pattern: /^[0-9]{10}$/, // Une expression régulière pour valider le format du numéro de téléphone
                  message:
                    "Le numéro de téléphone doit être composé de 10 chiffres",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="dateNaissance"
              label="Date de naissance"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une date de naissance",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY"/>
            </Form.Item>
          </>
        )}

        {/* Etape 3: Adresse */}
        {currentStep === 2 && (
          <>
            <Form.Item
              name="pays"
              label="Pays"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un pays",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ville"
              label="Ville"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une ville",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="adresse"
              label="Adresse"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une adresse",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="codePostal"
              label="Code postal"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un code postal",
                  whitespace: true,
                },
                {
                  pattern: /^\d{5}$/, // Une expression régulière pour valider le format du code postal (5 chiffres)
                  message: "Le code postal doit être composé de 5 chiffres",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        {/* Etape 4: Administratif */}
        {currentStep === 3 && (
          <>
            <Form.Item
              name="dateFinAgrement"
              label="Date de fin d'agrement"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une date de fin d'agrement",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY"/>
            </Form.Item>
            <Form.Item
              name="dateFinAssuranceHabitation"
              label="Date de fin d'assurance habitation"
              rules={[
                {
                  required: true,
                  message:
                    "Merci de saisir une date de fin d'assurance habitation",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY"/>
            </Form.Item>
            <Form.Item
              name="dateFinAssuranceVoiture"
              label="Date de fin d'assurance voiture"
              rules={[
                {
                  required: true,
                  message:
                    "Merci de saisir une date de fin d'assurance voiture",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </>
        )}

        <Item wrapperCol={{ offset: 8, span: 16 }}>
          {currentStep === 0 && (
            <Link to="/login">
              <Button type="primary" style={{ margin: "0 8px" }}>
                Retour
              </Button>
            </Link>
          )}
          {currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={handlePrev}>
              Précédent
            </Button>
          )}
          {currentStep < 3 && (
            <Button type="primary" onClick={handleNext}>
              Suivant
            </Button>
          )}
          {currentStep === 3 && (
            <Button type="primary" htmlType="submit">
              S'inscrire
            </Button>
          )}
        </Item>

        <Divider />

        <Steps current={currentStep}>
          <Step title="Identifiants" />
          <Step title="Informations" />
          <Step title="Adresse" />
          <Step title="Administratif" />
        </Steps>
      </Form>
    </div>
  );
};

export default SignInPage;
