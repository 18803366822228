import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./LoginPageForm.css";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const LoginPageForm = (props) => {
  const { handleDisplayForgot } = props;

  const { isAuthenticated, login, logout, loginWithApi, loginError } =
    useAuth(); // Destructure the login function from the useAuth hook
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await loginWithApi(values.email, values.password);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      // Naviguer vers "/" seulement si l'utilisateur est authentifié
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Form
      name="normal_login"
      size="large"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <h6>Merci de saisir vos identifiants</h6>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Merci de saisir une adresse email valide",
          },
          {
            type: "email",
            message: "Merci de saisir une adresse email valide",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Merci de saisir un mot de passe",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Mot de passe"
        />
      </Form.Item>

      <Form.Item>
        <a className="login-form-forgot" onClick={handleDisplayForgot}>
          Mot de passe oublié
        </a>
      </Form.Item>

      {loginError && <span className="error-message">{loginError}</span>}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading}
        >
          Se connecter
        </Button>
      </Form.Item>

      <Row justify="center" >
        <Col>
          <Link to="/signin/asmat">
            <Button type="link">Inscription assistante maternelle</Button>
          </Link>
        </Col>
      </Row>


    </Form>
  );
};

export default LoginPageForm;
