// Sidebar.js
import React, { useState } from "react";
import { Layout, Menu, Badge } from "antd";
import { UserOutlined, BellOutlined, ContactsOutlined } from "@ant-design/icons";
import { useSidebar } from "../../context/SideBarContext";
import { useNotification } from "../../context/NotificationContext";
import { useAuth } from "../../context/AuthContext";

import "./Sidebar.css";

const { Sider } = Layout;

const Sidebar = () => {
  const { siderOpen, headerOpen, setSiderOpen, setHeaderOpen } = useSidebar();

  const { notifications, markNotificationsAsRead } = useNotification();

  const { user } = useAuth();

  const listNotifications = notifications.notifications || [];

  const notifOuvert = () => {
    markNotificationsAsRead();
  }

  const items = [
    {
      label: user["Nom complet"],
      key: "user",
      icon: <UserOutlined className="iconSidebar" />,
      children: [
        {
          label: "Modifier le profil", key: "edit", onClick: () => {
            window.location.href = "/editProfil";
          }, },
        {
          label: "Se déconnecter",
          key: "logout",
          onClick: () => {
            window.location.href = "/logout";
          },
        },
      ],
    },
    {
      label: "Notifications",
      key: "notif",
      onMouseEnter: notifOuvert,
      icon: (
        <Badge count={notifications.nbr_notification} size="small">
          <BellOutlined className="iconSidebar" />
        </Badge>
      ),
      children: listNotifications.map((uneNotif, index) => ({
        label: uneNotif.Message,
        key: `notification_${index}`,
      })),
    },
  ];

  const isMobileOrTablet = window.innerWidth <= 1300;
  return (
    <Sider
      collapsible
      collapsed={siderOpen}
      onCollapse={(value) => {
        if (headerOpen) {
          setHeaderOpen(false);
          setTimeout(() => {
            setSiderOpen(value);
          }, "600");
        } else {
          setSiderOpen(value);
        }
      }}
      theme="light"
      reverseArrow="true"
      collapsedWidth={isMobileOrTablet ? 0 : 50}
      width={isMobileOrTablet ? 250 : 300}
    >
      <div />

      <Menu theme="light" mode="inline" items={items} selectable={false}></Menu>
    </Sider>
  );
};

export default Sidebar;
