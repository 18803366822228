// Fonction pour télécharger
export const Download = async (url) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    const blob = await response.blob();

    // Créer un lien temporaire et déclencher le téléchargement
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = getFileNameFromUrl(url); // Utilisez une fonction pour extraire le nom du fichier de l'URL
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error('Error during file download:', error);
  }

};

// Fonction utilitaire pour extraire le nom du fichier de l'URL
const getFileNameFromUrl = (url) => {
  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
};


export const AddRepertoire = async ({ nom, parentId, token, forceUpdate }) => {
  const url = "https://api.uama.fr/v1.0/explorer/addRepertoire";

  try {
    const response = await fetch(url, {
      method: "POST", // Specify the method
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ nom, parentId }), // Convert the JavaScript object to a JSON string
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    forceUpdate();
  } catch (error) {}
};

export const DeleteRepertoire = async ({ idRepertoire, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/deleteRepertoire/${idRepertoire}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("Repertoire deleted successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during deleting repertoire:", error);
  }
};

export const DeleteFichier = async ({ idFichier, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/deleteFichier/${idFichier}`;

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("Fichier deleted successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during deleting Fichier:", error);
  }
};

export const EditRepertoire = async ({ nom, idFolder, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/updateRepertoire/${idFolder}`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ nouveauNom: nom }),
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("Repertoire rename successfully");

    forceUpdate();
  } catch (error) {
    console.error("Error during rename repertoire:", error);
  }
};

export const EditFichier = async ({ nom, idFile, file, token, forceUpdate }) => {
  
  const url = `https://api.uama.fr/v1.0/explorer/updateFichier/${idFile}`;

  const formData = new FormData();
  formData.append("nom", nom);
  formData.append("fichier", file);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("File updated successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during updating file:", error);
  }
};

export const AddFichier = async ({ nom, idFolder, file, token, forceUpdate }) => {
  const url = `https://api.uama.fr/v1.0/explorer/addFichier`;


  const formData = new FormData();
  formData.append("nom", nom);
  formData.append("repertoireId", idFolder);
  formData.append("fichier", file);


  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    console.log("File updated successfully");
    forceUpdate();
  } catch (error) {
    console.error("Error during ADD file:", error);
  }
};
