import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Col, Row, message, DatePicker, Divider, ColorPicker } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import './ContratEdit.css';
import dayjs from 'dayjs';
import { useAuth } from "../../../context/AuthContext.js";

const { Option } = Select;

const ContratEdit = ({ contrat }) => {
    const [form] = Form.useForm();
    const [conges, setConges] = useState([]);

    console.log(contrat.Couleur)

    const { token } = useAuth();

    console.log(contrat);

    useEffect(() => {
        if (contrat && contrat["Absences prévues au contrat"]) {
            const absences = contrat["Absences prévues au contrat"];
            const newConges = absences.map(absence => {
                const dates = absence.split(" au ");
                const dateDebut = dayjs(dates[0], 'DD/MM/YYYY');
                const dateFin = dayjs(dates[1], 'DD/MM/YYYY');
                return { dateDebut, dateFin };
            });
            setConges(newConges);
        }
    }, [contrat]);

    console.log(contrat.UUID);
    console.log(token)

    const onFinish = async (values) => {
        try {
            if (values.couleur) {
                // Conversion de la couleur en format hexadécimal
                const hexColor = values.couleur.toHexString();
                values.couleur = hexColor;
            }


            const response = await fetch(`https://api.uama.fr/v1.0/contrat/assistante_maternelle/${contrat.UUID}`, {
                method: 'PUT',
                headers: {
                     Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nomEnfant: values.nomEnfant,
                    prenomEnfant: values.prenomEnfant,
                    couleur: values.couleur,
                    statutContrat: values.statutContrat,
                    conges: conges.map(conge => ({
                        dateDebut: conge.dateDebut.format('YYYY-MM-DD'),
                        dateFin: conge.dateFin.format('YYYY-MM-DD')
                    }))
                })
                
            });

            if (response.ok) {

                const queryParams = new URLSearchParams();
                queryParams.append('successMessage', 'Votre contrat a bien été mis à jour');
                window.location.href = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;
                
            } else {
                console.log();
                const errorData = await response.json(); // Récupérer les données d'erreur du serveur
                message.error(errorData.message); // Afficher le message d'erreur
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour du contrat :', error);
            console.log(error)

        }
    };

    const handleAddConge = () => {
        setConges([...conges, { dateDebut: null, dateFin: null }]);
    };

    const handleRemoveConge = (index) => {
        const updatedConges = [...conges];
        updatedConges.splice(index, 1);
        setConges(updatedConges);
    };

    const handleCongeChange = (index, field, value) => {
        const updatedConges = [...conges];
        updatedConges[index][field] = value;
        setConges(updatedConges);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                statutContrat: contrat.Statut,
                nomEnfant: contrat.Enfant.Nom,
                prenomEnfant: contrat.Enfant.Prenom

            }}
            className="contrat-edit-form"
        >
            <Row>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="nomEnfant"
                        label="Nom de l'enfant"
                        rules={[{ required: true, message: "Veuillez saisir le nom de l'enfant" }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="prenomEnfant"
                        label="Prénom de l'enfant"
                        rules={[{ required: true, message: "Veuillez saisir le prénom de l'enfant" }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="couleur"
                        label="Couleur"
                    >
                        <ColorPicker defaultValue={contrat.Couleur} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="statutContrat"
                        label="Statut du contrat"
                        rules={[{ required: true, message: 'Veuillez sélectionner le statut du contrat' }]}
                    >
                        <Select>
                            <Option value="En préparation">En préparation</Option>
                            <Option value="Actif">Actif</Option>
                            <Option value="Terminé">Terminé</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Divider>Gestion des absences prévues au contrat</Divider>
            {conges.map((conge, index) => (
                <div key={index} className="conge-item">
                    <Row>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={`Date de début de l'absence ${index + 1}`}
                                rules={[{ required: true, message: "Veuillez sélectionner la date de l'absence !" }]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    onChange={(date) => handleCongeChange(index, 'dateDebut', date)}
                                    value={conge.dateDebut}
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                label={`Date de fin de l'absence ${index + 1}`}
                                rules={[{ required: true, message: "Veuillez sélectionner date de l'absence!" }]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    onChange={(date) => handleCongeChange(index, 'dateFin', date)}
                                    value={conge.dateFin}
                                    format="DD-MM-YYYY"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="deleteCongesEdit">
                        <Button type="text" danger onClick={() => handleRemoveConge(index)}>
                            Supprimer cette abscence
                        </Button>
                    </div>
                </div>
            ))}
            <Form.Item>
                <Button
                    type="dashed"
                    onClick={handleAddConge}
                    style={{ width: "100%" }}
                    icon={<PlusOutlined />}
                >
                    Ajouter un congé
                </Button>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                    Soumettre
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ContratEdit;
