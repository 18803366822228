import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { Spin, Card, Row, Col, Alert } from "antd";

import "./ContratsActif.css"

const ContratsActif = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [contrats, setContrats] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContrats();
  }, []);

  const fetchContrats = async () => {
    try {
      const response = await fetch(
        "https://api.uama.fr/v1.0/contrat/index?limit=inf&option=Actif",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setContrats(data.contrats);
        setLoading(false);
      } else {
        const errorData = await response.json();
        setLoading(false);
        setError(errorData.message);
      }
    } catch (error) {
      console.error("Error fetching contracts:", error);
      setLoading(false);
    }
  };

  return (
    <div className="cardContractActif" style={{ overflowY: "auto"}}>
      {error ? (
        <Alert message={error} type="error" />
      ) : loading ? (
        <Spin />
      ) : (
        <Row >
          {contrats.map((contrat) => (
            <Col key={contrat.Enfant.UUID} xs={24} sm={24} md={11} lg={11} offset={1}>
              <Card title={contrat.Enfant["Nom complet"]} size="small">
                <p>
                  <strong>Age:</strong> {contrat.Enfant.Age}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default ContratsActif;
