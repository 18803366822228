import React, { useState, useEffect } from "react";
import { Form, Input, DatePicker, Button, Steps, Divider, message, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import dayjs from 'dayjs';

import axios from "axios";

import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const { Item } = Form;
const { Step } = Steps;

const EditProfil = () => {

  const [drapeauModifer, setDrapeauModifer] = useState(false);
  const { user, token, setUser } = useAuth();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();

  console.log(user)
  //FormData pour stocker les valeur des form pour les différentes pages.
  const handleNext = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData({ ...formData, ...values }); // Merge values into formData
        setCurrentStep(currentStep + 1);
      })
      .catch((error) => {
        console.log("Validation Error:", error);
        message.error("Veuillez remplir correctement tous les champs.");
      });
  };
  useEffect(() => {
    // La fonction que vous voulez appeler lorsque maVariable change
    console.log(currentStep)
  }, [currentStep]); // La dépendance maVariable spécifie quand le useEffect doit être exécuté


  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  //values pour les 3 valeurs de la dernières parties
  const onFinish = async (values) => {


    try {
      const response = await axios.put(
        `https://api.uama.fr/v1.0/user/update/${user.UUID}`,
        {
          dateFinAgrement: values.dateFinAgrement.format("YYYY-MM-DD"),
          dateFinAssuranceHabitation: values.dateFinAssuranceHabitation.format("YYYY-MM-DD"),
          dateFinAssuranceVoiture: values.dateFinAssuranceVoiture.format("YYYY-MM-DD"),
          email: formData.email,
          numeroTelephone: formData.numeroTelephone,
          dateNaissance: formData.dateNaissance.format("YYYY-MM-DD"),
          prenom: formData.prenom,
          nom: formData.nom,
          adresse: formData.adresse,
          codePostal: formData.codePostal,
          ville: formData.ville,
          pays: formData.pays,
          // Rajouter les champs attention values contient les 3 derniers valeurs de la fenetres
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }

      );


      console.log("API Response:", response.data);
      setUser(response.data.user);
      message.success("Modification réussi");
      navigate("/");
      // Handle success or navigate to the next step as needed
    } catch (error) {
      console.error("API Error:", error.response.data.message);
      message.error(error.response.data.message);
    }



  };


  //La fonction handleFormFinish s'appelle a l'etape 3 et l'étape 4. A revoir
  const handleFormFinish = (values) => {
    if (!drapeauModifer) {
      setDrapeauModifer(true)
    } else {
      onFinish(values);
    }
  };


  return (
    <div className="signIn-container">

      <Form
        form={form}
        className="signIn-form"
        onFinish={handleFormFinish}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 24 }}
      >
        {/* Etape 1: Identifiants */}
        {currentStep === 0 && (
          <>
            <Form.Item
              name="email"
              label="Email"
              initialValue={user.Email}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une adresse email valide",
                },
                {
                  type: "email",
                  message: "Merci de saisir une adresse email valide",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              name="password"
              label="Mot de passe"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un mot de passe",
                },
                {
                  min: 8,
                  message: "Le mot de passe doit avoir au moins 8 caractères",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="passwordConfirmation"
              label="Confirmation du mot de passe"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Merci de confirmer votre mot de passe",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Les mots de passe ne correspondent pas ")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item> */}
          </>
        )}

        {/* Etape 2: Informations */}
        {currentStep === 1 && (
          <>
            <Form.Item
              name="nom"
              label="Nom"
              initialValue={user.Nom}
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un nom",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="prenom"
              label="Prénom"
              initialValue={user["Prénom"]}
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un prénom",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="numeroTelephone"
              label="Numéro de téléphone"
              initialValue={user["Téléphone"]}
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un numéro de téléphone",
                },
                {
                  pattern: /^[0-9]{10}$/, // Une expression régulière pour valider le format du numéro de téléphone
                  message:
                    "Le numéro de téléphone doit être composé de 10 chiffres",
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="dateNaissance"
              label="Date de naissance"
              initialValue={dayjs(user["Date de naissance"], 'DD-MM-YYYY')}
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une date de naissance",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY"/>
            </Form.Item>
          </>
        )}

        {/* Etape 3: Adresse */}
        {currentStep === 2 && (
          <>
            <Form.Item
              name="pays"
              label="Pays"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un pays",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ville"
              label="Ville"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une ville",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="adresse"
              label="Adresse"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une adresse",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="codePostal"
              label="Code postal"
              rules={[
                {
                  required: true,
                  message: "Merci de saisir un code postal",
                  whitespace: true,
                },
                {
                  pattern: /^\d{5}$/, // Une expression régulière pour valider le format du code postal (5 chiffres)
                  message: "Le code postal doit être composé de 5 chiffres",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        {/* Etape 4: Administratif */}
        {currentStep === 3 && (
          <>
            <Form.Item
              name="dateFinAgrement"
              label="Date de fin d'agrement"
              initialValue={dayjs(user["Date de fin d'agrément"], 'DD-MM-YYYY')}
              rules={[
                {
                  required: true,
                  message: "Merci de saisir une date de fin d'agrement",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY"/>
            </Form.Item>
            <Form.Item
              name="dateFinAssuranceHabitation"
              label="Date de fin d'assurance habitation"
              initialValue={dayjs(user["Date de fin assurance habitation"], 'DD-MM-YYYY')}
              rules={[
                {
                  required: true,
                  message:
                    "Merci de saisir une date de fin d'assurance habitation",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY"/>
            </Form.Item>
            <Form.Item
              name="dateFinAssuranceVoiture"
              label="Date de fin d'assurance voiture"
              initialValue={dayjs(user["Date de fin assurance voiture"], 'DD-MM-YYYY')}
              rules={[
                {
                  required: true,
                  message:
                    "Merci de saisir une date de fin d'assurance voiture",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY"/>
            </Form.Item>
          </>
        )}

        <Item wrapperCol={{ offset: 8, span: 16 }}>
          {currentStep === 0 && (
            <Link to="/">
              <Button type="primary" style={{ margin: "0 8px" }}>Retour</Button>
            </Link>
          )}
          {currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={handlePrev}>
              Précédent
            </Button>
          )}
          {currentStep < 3 && (
            <Button type="primary" onClick={handleNext}>
              Suivant
            </Button>
          )}
          {currentStep === 3 && (
            <Button type="primary" htmlType="submit">
              Modifier
            </Button>
          )}
        </Item>

        <Divider />

        <Steps current={currentStep}>
          <Step title="Identifiants" />
          <Step title="Informations" />
          <Step title="Adresse" />
          <Step title="Administratif" />
        </Steps>
      </Form>
    </div>
  );
};

export default EditProfil;
