import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const RoleProtectedRoute = ({ element, allowedRoles }) => {
  const { isAuthenticated, user } = useAuth();

  const hasRequiredRole = user && allowedRoles.some(role => user.Roles.includes(role));

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!hasRequiredRole) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default RoleProtectedRoute;
