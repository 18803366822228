import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrap5 from "@fullcalendar/bootstrap5";
import listPlugin from "@fullcalendar/list";
import scrollgrid from "@fullcalendar/scrollgrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Button } from "antd";

import { useAuth } from "../../context/AuthContext";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Planning.css";
import PlanningModalEdit from "./PlanningModalEdit/PlanningModalEdit";
import PlanningModalAdd from "./PlanningModalAdd/PlanningModalAdd";
import PlanningModalArretMaladie from "./PlanningModalArretMaladie/PlanningModalArretMaladie";

const Planning = ({ initialView, height, openAddEventModal, addEvent, addEventModalVisible, closeAddEventModal }) => {
  const [holidays, setHolidays] = useState([]);
  const [events, setEvents] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleMaladie, setModalVisibleMaladie] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { token, user } = useAuth();
  
  var isMobile = window.innerWidth <= 1300;

  const isAssistanteMaternelle = user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE');

  const refreshEvents = () => {
    fetchCustomEvents();
  }

  const cancelModalEdit = () => {
    setSelectedEvent("");
    setModalVisible(false);
  }

  const closeEventModalMaladie = () => {
    setModalVisibleMaladie(false);
  }

  if (!addEvent) {
    isMobile = true;
  }

  const fetchHolidays = async (year) => {
    try {
      const response = await fetch(
        `https://api.uama.fr/v1.0/planning/jours-feries?annee=${year}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data.joursFeries;
    } catch (error) {
      console.error(
        `Erreur lors de la récupération des jours fériés pour l'année ${year}:`,
        error
      );
      return [];
    }
  };

  const getEventClassNames = (arg) => {
    const { event } = arg;
    let classNames = "custom-event";

    if (event.extendedProps.status === "Accepté") {
      classNames += " accepted-event";
    } else if (event.extendedProps.status === "Refusé") {
      classNames += " rejected-event";
    } else if (event.extendedProps.status === "En attente de validation") {
      classNames += " pending-event";
    }

    return classNames;
  };

  const fetchAllHolidays = async () => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const nextYear = currentYear + 1;

    const currentYearHolidays = await fetchHolidays(currentYear);
    const previousYearHolidays = await fetchHolidays(previousYear);
    const nextYearHolidays = await fetchHolidays(nextYear);

    const allHolidays = [
      ...currentYearHolidays,
      ...previousYearHolidays,
      ...nextYearHolidays,
    ];

    setHolidays(allHolidays);
  };

  const fetchCustomEvents = async () => {
    try {
      const response = await fetch("https://api.uama.fr/v1.0/planning/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setEvents(data);
        console.log(data);
      } else {
        console.warn("No events found in the API response:", data);
      }
    } catch (error) {
      console.error("Error fetching custom events:", error);
    }
  };

  useEffect(() => {
    fetchAllHolidays();
    fetchCustomEvents();
  }, [token]);

  const handleDateClick = (arg) => {
    // alert(arg.dateStr);
  };

  const handleEventClick = (info) => {
    setSelectedEvent(info.event); // Stocker l'événement sélectionné
    setModalVisible(true); // Ouvrir le modal
    console.log(info.event);
  };

  const handleAddEventButtonClick = () => {
    openAddEventModal();
  };

  const handleMaladieButtonClick = () => {
    setModalVisibleMaladie(true);
  };

  console.log("🚀 ~ Planning ~ user:", user.Roles);

  return (
    <div>
      <FullCalendar
        plugins={[
          interactionPlugin,
          dayGridPlugin,
          timeGridPlugin,
          bootstrap5,
          listPlugin,
          scrollgrid,
        ]}
        initialView={initialView}
        locale="fr"
        dayMinWidth={isMobile ? "200" : "200"}
        themeSystem="bootstrap5"
        firstDay={1}
        height={height}
        events={[
          ...events.map((event) => ({
            title: event.title,
            start: event.start,
            end: event.end,
            color: event.color,
            textColor: event.textColor,
            eventId: event.id,
            malade: event.malade,
            status: event.status,
            heureComplementaire: event.heureComplementaire,
            periode: event.periode,
            type: "event",
          })),
          ...holidays.map((holiday) => ({
            title: holiday.title,
            start: holiday.date,
            end: holiday.date,
            color: holiday.color,
            textColor: holiday.textColor,
            type: "holiday",
          })),
        ]}
        headerToolbar={{
          left: "prev,next,today",
          center: "title",
          right: isMobile ? "" : isAssistanteMaternelle ? "addEventButton addMaladieButton dayGridMonth,timeGridWeek,timeGridDay,listWeek" : "addEventButton dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        noEventsContent="Aucun événement cette semaine"
        allDayText="Journée Entière"
        buttonText={{
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
          listWeek: "Liste",
        }}
        eventClick={(info) => handleEventClick(info)}
        dateClick={(arg) => handleDateClick(arg)}
        eventClassNames={getEventClassNames}
        customButtons={{
          addEventButton: {
            text: '+ Ajouter un événement',
            click: handleAddEventButtonClick,
          },
          ...(isAssistanteMaternelle && {
            addMaladieButton: {
              text: '+ Ajouter un arrêt maladie',
              click: handleMaladieButtonClick,
            },
          }),
        }}
      />

      <Modal
        title={selectedEvent ? selectedEvent.title : ""}
        open={modalVisible}
        onCancel={() => cancelModalEdit()}
        footer={null}
      >
        <PlanningModalEdit cancelModalEdit={cancelModalEdit} refreshEvents={refreshEvents} selectedEvent={selectedEvent} />
      </Modal>

      <Modal
        title="Ajouter un événement"
        open={addEventModalVisible}
        onCancel={closeAddEventModal}
        footer={null}
      >
        <PlanningModalAdd refreshEvents={refreshEvents} closeAddEventModal={closeAddEventModal} />
      </Modal>

      <Modal
        title="Ajouter un arrêt malaide"
        open={modalVisibleMaladie}
        onCancel={closeEventModalMaladie}
        footer={null}
      >
        <PlanningModalArretMaladie refreshEvents={refreshEvents} closeEventModalMaladie={closeEventModalMaladie} />
      </Modal>
    </div>
  );
};

export default Planning;
