import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import RoleProtectedRoute from "./RoleProtectedRoute"; 
import HomePage from "../view/HomePage/HomePage";
import PlanningPage from "../view/PlanningPage/PlanningPage";
import ImpotsPage from "../view/ImpotsPage/ImpotsPage";
import DocumentsPage from "../view/DocumentsPage/DocumentsPage";
import LogoutPage from "../view/Auth/LogoutPage/LogoutPage";
import ForgotPasswordPasswordForm from "../components/Auth/ForgotPassword/ForgotPasswordPasswordForm/ForgotPasswordPasswordForm";
import LoginPage from "../view/Auth/LoginPage/LoginPage";
import ForgotPassword from "../view/Auth/ForgotPassword/ForgotPassword";
import SignInAsmat from "../view/Auth/SignInPage/SignInAsmat/SignInAsmat";
import SignInParent from "../view/Auth/SignInPage/SignInParent/SignInParent";
import DocumentsAll from "../view/DocumentsPage/DocumentsAll/DocumentsAll";
import DocumentsPerso from "../view/DocumentsPage/DocumentsPerso/DocumentsPerso";
import DocumentsParents from "../view/DocumentsPage/DocumentsParents/DocumentsParents";
import EditProfil from "../view/Auth/EditProfil/EditProfil";
import ContratsPage from "../view/ContratsPage/ContratsPage";
import Page404 from "../components/Page404/Page404";

const Routes = [
  {
    path: "/",
    element: <ProtectedRoute element={<HomePage />} />,
  },
  {
    path: "/Impots",
    element: <RoleProtectedRoute element={<ImpotsPage />} allowedRoles={['ROLE_ASSISTANTE_MATERNELLE']} />,
  },
  {
    path: "/Planning",
    element: <ProtectedRoute element={<PlanningPage />} />,
  },
  {
    path: "/contrats",
    element: <ProtectedRoute element={<ContratsPage />} />,
  },

  // Connectivité :
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/forgotPassword/:id",
    element: <ForgotPassword />,
  },
  {
    path: "/signin/asmat",
    element: <SignInAsmat />,
  },
  {
    path: "/signin/parent/:idParent",
    element: <SignInParent />,
  },
  {
    path: "/editProfil",
    element: <ProtectedRoute element={<EditProfil />} />,
  },

  // Documents Page :
  {
    path: "/documents",
    element: <ProtectedRoute element={<DocumentsPage />} />,
  },
  {
    path: "/documents/documentsAll",
    element: <ProtectedRoute element={<DocumentsAll />} />,
  },
  {
    path: "/documents/documentsPerso",
    element: <ProtectedRoute element={<DocumentsPerso />} />,
  },
  {
    path: "/documents/DocumentsParents/:idRepertoire",
    element: <ProtectedRoute element={<DocumentsParents />} />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default Routes;
