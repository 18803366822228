// ForgotPasswordPassword.jsx
import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import "./ForgotPasswordPasswordForm.css"


const ForgotPasswordPassword = () => {

  const { id } = useParams(); // Get the "id" parameter from the URL
  console.log(id);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    const resetPasswordEndpoint = `https://api.uama.fr/password/reset/${id}`;

    try {
      // Make a request to the password reset API
      const response = await fetch(resetPasswordEndpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: values.password,
        }),
      });

      if (response.statusText == "OK") {
        // If the password reset was successful, display a success message
        message.success("Le mot de passe a été réinitialisé avec succès.");
        navigate("/login");
      } else {
        // If there was an error in the password reset process, display an error message
        message.error("Une erreur s'est produite lors de la réinitialisation du mot de passe.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("Une erreur s'est produite lors de la réinitialisation du mot de passe. (Erreur Serveur)");
      // Handle other potential errors here
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <Form
        name="ForgotPasswordPassword"
        size="large"
        className="login-form"
        onFinish={onFinish}
      >
        <h6>Merci de saisir votre nouveau mot de passe</h6>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Merci de saisir un mot de passe",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Nouveau mot de passe"
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Merci de confirmer votre mot de passe',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Les deux mots de passe ne correspondent pas')
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Confirmer le mot de passe"
          />
        </Form.Item>

        <a href="/Login" className={"retour-login"}>Se connecter</a>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="forgot-password-password-button"
            loading={loading}
          >
            Réinitialiser le mot de passe
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPasswordPassword;
