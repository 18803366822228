import React from "react";
import Planning from "../../components/Planning/Planning";
import "./HomePage.css";

import ResumeAbscence from "../../components/ResumeAbscence/ResumeAbscence";

import { Col, Card, Row } from "antd";
import CardWelcome from "../../components/CardWelcome/CardWelcome";

import { useAuth } from "../../context/AuthContext";

import {
  CarryOutOutlined,
  CalculatorOutlined,
  CarOutlined,
  ReconciliationOutlined
} from "@ant-design/icons";

import { GiPalmTree } from "react-icons/gi";
import ContratsActif from "../../components/ContratsActif/ContratsActif";

const HomePage = () => {


  const { user,token } = useAuth();
  console.log(user);
  console.log(token);

  const isMobile = window.innerWidth <= 1300;


  return (
    <div className="homeContent">
      <Row justify="space-around" align="middle" className="row">
        <Col xs={24} sm={12} md={12} lg={10} xl={12}>
          <CardWelcome nomComplet={user["Nom complet"]} />

          <Card
            className="planningCard mt-3"
            headStyle={{ backgroundColor: "#FDF0F0" }}
            extra={<a href="/Planning">Ajouter</a>}
            title={
              <span className="title">
                <CarryOutOutlined className="icon" /> Planning de la semaine
              </span>
            }
            size="small"
          >
            <Planning initialView={isMobile ? "listWeek" : "timeGridWeek"} height={"65vh"} addEvent={false} />
          </Card>
        </Col>

        

        <Col xs={24} sm={11} md={11} lg={10} xl={11}>
          <Card
            className="cpCard"
            headStyle={{ backgroundColor: "#FDF0F0" }}
            extra={<a href="/conges-payes">Accéder aux contrats</a>}
            title={
              <span className="title">
                <ReconciliationOutlined className="icon" /> Contrats actifs
              </span>
            }
          >
            <ContratsActif/>
          </Card>

          <Card
            className="cpCard"
            headStyle={{ backgroundColor: "#FDF0F0" }}
            title={
              <span className="title">
                <GiPalmTree className="icon" /> Les prochaines abscence prévues au contrat
              </span>
            }
          >
            <ResumeAbscence />
          </Card>
          
        </Col>

        
      </Row>
    </div>
  );
};

export default HomePage;
