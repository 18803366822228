import React, { useState, useEffect } from 'react';
import { Upload, Button, List, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom'; // Importer useParams

import { useDocuments } from '../../../context/DocumentsContext';



import ArborescenceDocuments from "../../../components/Documents/ArborescenceDocuments";


import '../DocumentsGlobal.css';
import Loading from '../../../components/Loading/Loading';

const DocumentsParents = () => {
    const [fileList, setFileList] = useState([]);
    const { documents, forceUpdate } = useDocuments();
    const identifiant = +window.location.href.split('/')[window.location.href.split('/').length - 1]
    const repertoireGlobal = documents?.contient.find(
        (item) => item.id === identifiant
    );
    console.log(documents);
    console.log(repertoireGlobal)

    // Ajout d'un état pour le chargement
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Mettre à jour l'état de chargement après une seconde
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        // Nettoyer le timer lors du démontage du composant
        return () => clearTimeout(timer);
    }, []); // Utilise un tableau vide pour s'assurer que cela ne se déclenche qu'une seule fois

    if (loading) {
        return <Loading />; // ou un autre indicateur de chargement
    }

    if (!documents) {
        return null; // Ou un message indiquant l'absence de documents
    }

    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleRemove = (file) => {
        const newFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(newFileList);
    };

    return (
        <div className="documentContent ">
            <Row justify="space-around" align="middle">
                <Col xs={24} sm={24} md={20} lg={16} xl={14} className="documentsCol">
                    <ArborescenceDocuments documents={repertoireGlobal} forceUpdate={forceUpdate} />
                </Col>
            </Row>
        </div>
    );
};

export default DocumentsParents;
