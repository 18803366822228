import React, { useEffect, useState } from 'react';
import { Card, message, Modal } from 'antd';
import { EditOutlined, InfoCircleOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useAuth } from '../../../context/AuthContext';
import ImpotsTab from '../ImpotsTab/ImpotsTab';

const ImpotsCard = ({ impots, selectedContrat, refresh, nomEnfant }) => {
    const { token } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [statusModal, setStatusModal] = useState();

    const showModal = (status) => {
        setIsModalVisible(true);
        setStatusModal(status);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Card
                title={
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {`Impôts ${impots.annee}`}
                        {impots.isDeclared
                            ? <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
                            : <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
                        }
                    </div>
                }
                actions={
                    !impots.isDeclared
                        ? [
                            <EditOutlined style={{ color: 'red' }} key="edit" onClick={() => showModal("edit")} />,
                            <InfoCircleOutlined key="info" onClick={() => showModal("info")} />,
                        ]
                        : [
                            <DeleteOutlined style={{ color: 'red' }} key="delete" onClick={() => showModal("delete")} />,
                            <InfoCircleOutlined key="info" onClick={() => showModal("info")} />,
                        ]
                }
            >
                {!impots.isDeclared ?
                    <p>Les impôts doivent être complétés</p>
                    : <p>Les impôts ont été complétés</p>}
            </Card>
            <Modal
                title={`Tableau des impôts - ${impots.annee}`}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={statusModal === "delete" ? "30%" : "95%"}
            >
                <ImpotsTab
                    nomEnfant={nomEnfant}
                    refresh={refresh}
                    token={token}
                    statusModal={statusModal}
                    impots={impots}
                    selectedContrat={selectedContrat}
                    year={impots.annee}
                />
            </Modal>
        </>
    );
};

export default ImpotsCard;
