import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import './CardWelcome.css';

const CardWelcome = (props) => {

  const { nomComplet } = props

  const currentDate = new Date();
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('fr-FR', options);

  return (
    <div className="card-welcome">
      <UserOutlined className="icon" />
      <div className="welcome-message">
        <p className='title'>Bonjour {nomComplet}</p>
        <p className="date">{formattedDate}</p>
      </div>
    </div>
  );
}

export default CardWelcome;