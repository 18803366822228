import React, { useState } from "react";
import { Form, Input, DatePicker, Button, Steps, ColorPicker, Row, Col, Space, Divider, message } from "antd";
import { useAuth } from "../../../context/AuthContext";

import "./ContratAdd.css";

const { Step } = Steps;

const ContratAddEdit = ({ contrat }) => {
  const { token } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);

  const [formParent] = Form.useForm();
  const [formEnfant] = Form.useForm();
  const [formContrat] = Form.useForm();

  

  const [conges, setConges] = useState([]);
  const [formData, setFormData] = useState({});

  const handleAddConge = () => {
    setConges([...conges, { dateDebut: null, dateFin: null }]);
  };
  const handleRemoveConge = (index) => {
    const updatedConges = [...conges];
    updatedConges.splice(index, 1);
    setConges(updatedConges);
  };

  const handleCongeChange = (index, field, value) => {
    const updatedConges = [...conges];
    updatedConges[index][field] = value;
    setConges(updatedConges);
  };



  const onFinishParent = (values) => {
    console.log("Received values of parent form:", values);

    if (values.couleur) {
      const hexColor = values.couleur.toHexString();
      values.couleur = hexColor
    }

    setFormData({ ...formData, ...values });

    setCurrentStep(currentStep + 1);
  };

  const onFinishEnfant = (values) => {
    values.dateNaissanceEnfant = values.dateNaissanceEnfant.format('YYYY-MM-DD');
    console.log("Received values of enfant form:", values);
    setFormData({ ...formData, ...values });
    setCurrentStep(currentStep + 1);
  };

  const onFinishContrat = async (values) => {
    
    console.log("Received values of contrat form:", values);
    setFormData({ ...formData, ...values });
    console.log("All form data:", formData);



    // Add logic to send data to API with token

    // Préparation des données à envoyer
    const requestData = {
      ...formData,
      dateDebutContrat: values.dateDebutContrat.format('YYYY-MM-DD'),
      dateFinContrat: values.dateFinContrat.format('YYYY-MM-DD'),
      conges: conges.map(conge => ({
        dateDebut: conge.dateDebut.format('YYYY-MM-DD'),
        dateFin: conge.dateFin.format('YYYY-MM-DD')
      }))
    };

    try {
      // Envoi de la requête à l'API
      const response = await fetch('https://api.uama.fr/v1.0/contrat/assistante_maternelle/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {

        const queryParams = new URLSearchParams();
        queryParams.append('successMessage', 'Votre contrat a bien été mis à jour');
        window.location.href = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;

      } else {
        console.log();
        const errorData = await response.json(); // Récupérer les données d'erreur du serveur
        message.error(errorData.message); // Afficher le message d'erreur
      }

    } catch (error) {
      console.error('Erreur lors de la mise à jour du contrat :', error);
      console.log(error)

    }


  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: "Informations parent",
      content: (
        <Form
          form={formParent}
          name="parentForm"
          onFinish={onFinishParent}
          initialValues={{
            nomParent: contrat.nomParent,
            prenomParent: contrat.prenomParent,
            emailParent: contrat.emailParent,
          }}
        >
          <Form.Item
            label="Nom du parent"
            name="nomParent"

            rules={[{ required: true, message: "Veuillez entrer le nom du parent!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Prénom du parent"
            name="prenomParent"

            rules={[{ required: true, message: "Veuillez entrer le prénom du parent!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email du parent"
            name="emailParent"

            rules={[
              { required: true, message: "Veuillez entrer l'email du parent!" },
              { type: "email", message: "Veuillez entrer un email valide!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Couleur :"
            name="couleur"
          >
            <ColorPicker defaultValue="#dedede" format="hex"/>
          </Form.Item>

          {currentStep > 0 && (
            <Button type="primary" onClick={handlePrev}>
              Retour
            </Button>
          )}
          <Button type="primary" htmlType="submit">
            Suivant
          </Button>
        </Form>
      ),
    },
    {
      title: "Informations enfant",
      content: (
        <Form
          form={formEnfant}
          name="enfantForm"
          onFinish={onFinishEnfant}
          initialValues={{
            nomEnfant: contrat.nomEnfant,
            prenomEnfant: contrat.prenomEnfant,
            dateNaissanceEnfant: contrat.dateNaissanceEnfant,
          }}
        >
          <Form.Item
            label="Nom de l'enfant"
            name="nomEnfant"
            rules={[{ required: true, message: "Veuillez entrer le nom de l'enfant!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Prénom de l'enfant"
            name="prenomEnfant"
            rules={[{ required: true, message: "Veuillez entrer le prénom de l'enfant!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Date de naissance de l'enfant"
            name="dateNaissanceEnfant"
            rules={[{ required: true, message: "Veuillez entrer la date de naissance de l'enfant!" }]}
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>

          {currentStep > 0 && (
            <Button type="primary" onClick={handlePrev}>
              Retour
            </Button>
          )}
          <Button type="primary" htmlType="submit">
            Suivant
          </Button>
        </Form>
      ),
    },
    {
      title: "Dates de contrat",
      content: (
        <Form
          form={formContrat}
          name="contratForm"
          onFinish={onFinishContrat}
          initialValues={{
            dateDebutContrat: contrat.dateDebutContrat,
            dateFinContrat: contrat.dateFinContrat,
          }}
        >
          <div className="contrat-form-group">
            <Form.Item
              label="Date de début du contrat"
              name="dateDebutContrat"
              rules={[{ required: true, message: "Veuillez sélectionner la date de début du contrat!" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{
                width: "100%"
              }} />
            </Form.Item>

            <Form.Item
              label="Date de fin du contrat"
              name="dateFinContrat"
              rules={[{ required: true, message: "Veuillez sélectionner la date de fin du contrat!" }]}
            >
              <DatePicker format="DD-MM-YYYY"  style={{
                width: "100%"
              }} />
            </Form.Item>
          </div>

          <Divider>Gestion des absences prévues au contrat</Divider>

          {conges.map((conge, index) => (
            <div key={index} className="contrat-form-group">
              <Form.Item
                label={`Date de début de l'absence ${index + 1}`}
                rules={[{ required: true, message: "Veuillez sélectionner la date de début de l'absence" }]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  value={conge.dateDebut}
                  style={{
                    width: "100%"
                  }}
                  onChange={(date) => handleCongeChange(index, 'dateDebut', date)}
                />
              </Form.Item>
              <Form.Item
                label={`Date de fin de l'abscence ${index + 1}`}
                rules={[{ required: true, message: "Veuillez sélectionner la date de fin de l'abscence" }]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  value={conge.dateFin}
                  style={{
                    width: "100%"
                  }}
                  onChange={(date) => handleCongeChange(index, 'dateFin', date)}
                />
              </Form.Item>
              <Button style={{margin : '-10px'}} className="deleteConges" type="text" danger onClick={() => handleRemoveConge(index)}>
                Supprimer ce congé
              </Button>
            </div>
            
          ))}

          <Button type="dashed" onClick={handleAddConge} style={{ width: "100%", marginBottom: "20px" }}>
            + Ajouter un congé
          </Button>


          <div className="contrat-form-group">
            {currentStep > 0 && (
              <Button type="primary" onClick={handlePrev}>
                Retour
              </Button>
            )}
            <Button type="primary" htmlType="submit">
              Valider
            </Button>
          </div>
        </Form>
      ),
    },
  ];

  return (
    <div>
      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep].content}</div>
    </div>
  );
};

export default ContratAddEdit;
