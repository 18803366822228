// DocumentsContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useAuth } from "./AuthContext";

const DocumentsContext = createContext();

const DocumentsProvider = ({ children }) => {
    const [documents, setDocuments] = useState(null);
    const [updateKey, setUpdateKey] = useState(0); // Nouvelle clé d'état

    const { token } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.uama.fr/v1.0/explorer/index', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setDocuments(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token, updateKey]); // Ajoutez updateKey comme une dépendance

    // Fonction pour forcer la mise à jour
    const forceUpdate = () => setUpdateKey((prevKey) => prevKey + 1);

    return (
        <DocumentsContext.Provider value={{ documents, setDocuments, forceUpdate }}>
            {children}
        </DocumentsContext.Provider>
    );
};

const useDocuments = () => {
    const context = useContext(DocumentsContext);
    if (!context) {
        throw new Error('useDocuments must be used within a DocumentsProvider');
    }
    return context;
};

export { DocumentsProvider, useDocuments };
