import React, { useState } from "react";
import Planning from "../../components/Planning/Planning";
import { Button, Col, Row } from "antd";
import "./Planningpage.css";
import { useAuth } from "../../context/AuthContext";

const PlanningPage = () => {
  const [addEventModalVisible, setAddEventModalVisible] = useState(false);
  const { user } = useAuth();

  const isAssistanteMaternelle = user.Roles.includes('ROLE_ASSISTANTE_MATERNELLE');

  const openAddEventModal = () => {
    setAddEventModalVisible(true);
  };

  const closeAddEventModal = () => {
    setAddEventModalVisible(false);
  };

  const isMobile = window.innerWidth <= 1300;

  return (
    <div className="planningContent">
      <Row justify="space-around" align="middle" className="planningCol">
        {isMobile && (
          <Row justify={isAssistanteMaternelle ? "start" : "center"} style={{ width: "100%" }}>
            <Col span={isAssistanteMaternelle ? 12 : 24} className="mb-1">
              <Button
                type="primary"
                style={{ height: "50px", display: "block", margin: "0 auto" }}
                onClick={openAddEventModal}
              >
                + Ajouter un événement
              </Button>
            </Col>
            {isAssistanteMaternelle && (
              <Col span={10} className="mb-1">
                <Button
                  style={{ height: "50px" }}
                  type="primary"
                  onClick={() => console.log("maladie")}
                >
                  Ajouter un arrêt maladie
                </Button>
              </Col>
            )}
          </Row>
        )}
        <Col xs={24} sm={24} md={23} lg={23} xl={23}>
          <div className="view-container-bgWhite">
            <Planning
              closeAddEventModal={closeAddEventModal}
              addEventModalVisible={addEventModalVisible}
              openAddEventModal={openAddEventModal}
              height={"85vh"}
              initialView={"timeGridWeek"}
              addEvent={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PlanningPage;
