import React, { useState, useEffect } from "react";

import { ConfigProvider } from "antd";
import frFR from "antd/locale/fr_FR";

import Main from "./Main";
import { AuthProvider } from "./context/AuthContext";
import { SidebarProvider } from "./context/SideBarContext";
import { NotificationProvider } from "./context/NotificationContext";
import { DocumentsProvider } from './context/DocumentsContext';

import "./App.css";

function App() {
  return (
    <div className="App">
      {/* AntDesign en français */}
      <ConfigProvider locale={frFR}>
        {/* Contexte pour l'authentification */}

        <AuthProvider>
          <NotificationProvider>
            <SidebarProvider>
              <DocumentsProvider>
              
              <Main />

              </DocumentsProvider>
            </SidebarProvider>
          </NotificationProvider>
        </AuthProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
